import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";

const Components = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/dashboard`}
      component={asyncComponent(() => import("./Dashboard"))}
    />
    <Route
      path={`${match.url}/user_specific_data`}
      component={asyncComponent(() => import("./UserSpecificReport"))}
    />
    <Route
      path={`${match.url}/combined-usage-information`}
      component={asyncComponent(() => import("./CombinedReport"))}
    />
    <Route
      path={`${match.url}/lenders`}
      component={asyncComponent(() => import("./Lender"))}
    />
    <Route
      path={`${match.url}/employers`}
      component={asyncComponent(() => import("./Employer"))}
    />
    <Route
      path={`${match.url}/surveyor`}
      component={asyncComponent(() => import("./Surveyor"))}
    />
    <Route
      path={`${match.url}/researchers`}
      component={asyncComponent(() => import("./Researcher"))}
    />
    <Route
      path={`${match.url}/clients`}
      component={asyncComponent(() => import("./Clients"))}
    />
    <Route
      path={`${match.url}/financial_institutions`}
      component={asyncComponent(() => import("./FinInstitution"))}
    />
    <Route
      path={`${match.url}/administrators`}
      component={asyncComponent(() => import("./Administrator"))}
    />
    <Route
      path={`${match.url}/portal-users`}
      component={asyncComponent(() => import("./PortalUser"))}
    />

    <Route
      path={`${match.url}/language`}
      component={asyncComponent(() => import("./Language"))}
    />
    <Route
      path={`${match.url}/change-password`}
      component={asyncComponent(() => import("./ResetPassword"))}
    />
    <Route
      path={`${match.url}/forgot-password`}
      component={asyncComponent(() => import("./ForgotPassword"))}
    />

    <Route
      path={`${match.url}/ownership`}
      component={asyncComponent(() => import("./Ownership"))}
    />

    <Route
      path={`${match.url}/permission-groups`}
      component={asyncComponent(() => import("./DashboardPermissionGroups"))}
    />
    <Route
      path={`${match.url}/add-permission-groups`}
      component={asyncComponent(() =>
        import("./DashboardPermissionGroups/AddDashboardPermission")
      )}
    />
    <Route
      path={`${match.url}/edit-permission-groups/:id`}
      component={asyncComponent(() =>
        import("./DashboardPermissionGroups/EditDashboardPermissions")
      )}
    />
    <Route
      path={`${match.url}/employees/data`}
      component={asyncComponent(() => import("./Employees/Data/index"))}
    />
    <Route
      path={`${match.url}/employees/scheduled_data`}
      component={asyncComponent(() =>
        import("./Employees/ScheduledData/index")
      )}
    />
    <Route
      path={`${match.url}/employees/reports`}
      component={asyncComponent(() =>
        import("./Employees/GeneratedReports/index")
      )}
    />
    <Route
      path={`${match.url}/app-settings`}
      component={asyncComponent(() => import("./AppSettings"))}
    />
    <Route
      path={`${match.url}/add-app-setting`}
      component={asyncComponent(() => import("./AppSettings/AddAppSettings"))}
    />

    <Route
      path={`${match.url}/edit-app-setting/:id`}
      component={asyncComponent(() => import("./AppSettings/EditAppSettings"))}
    />
    <Route
      path={`${match.url}/portal-settings`}
      component={asyncComponent(() => import("./PortalSettings"))}
    />
    <Route
      path={`${match.url}/demographics`}
      component={asyncComponent(() => import("./Demographics"))}
    />
    <Route
      path={`${match.url}/add-demographics`}
      component={asyncComponent(() => import("./Demographics/AddDemographics"))}
    />
    <Route
      path={`${match.url}/edit-demographics/:id`}
      component={asyncComponent(() =>
        import("./Demographics/EditDemographics")
      )}
    />
    <Route
      path={`${match.url}/employee/attribute`}
      component={asyncComponent(() => import("./EmployeeForm"))}
    />
    <Route
      path={`${match.url}/add-employee/attribute`}
      component={asyncComponent(() => import("./EmployeeForm/AddEmployeeForm"))}
    />
    <Route
      path={`${match.url}/edit-employee/attribute/:id`}
      component={asyncComponent(() =>
        import("./EmployeeForm/EditEmployeeForm")
      )}
    />
    <Route
      path={`${match.url}/loan`}
      component={asyncComponent(() => import("./LoanQuestions"))}
    />
    <Route
      path={`${match.url}/add-loan`}
      component={asyncComponent(() => import("./LoanQuestions/AddLoan"))}
    />
    <Route
      path={`${match.url}/edit-loan/:id`}
      component={asyncComponent(() => import("./LoanQuestions/EditLoan"))}
    />
    <Route
      path={`${match.url}/employment`}
      component={asyncComponent(() => import("./EmploymentQuestions"))}
    />
    <Route
      path={`${match.url}/add-employment-question`}
      component={asyncComponent(() =>
        import("./EmploymentQuestions/AddEmploymentQ")
      )}
    />
    <Route
      path={`${match.url}/edit-employment-question/:id`}
      component={asyncComponent(() =>
        import("./EmploymentQuestions/EditEmploymentQ")
      )}
    />

    <Route
      path={`${match.url}/projects`}
      component={asyncComponent(() => import("./Projects"))}
    />
    <Route
      path={`${match.url}/project/:p_id/participants`}
      component={asyncComponent(() =>
        import("./Projects/ManageProject/participant")
      )}
    />
    <Route
      path={`${match.url}/manage-project/:id`}
      component={asyncComponent(() => import("./Projects/ManageProject"))}
    />
    <Route
      path={`${match.url}/project/:p_id/manage`}
      component={asyncComponent(() =>
        import("./Projects/ManageProject/Manage")
      )}
    />

    <Route
      path={`${match.url}/surveys`}
      component={asyncComponent(() => import("./Survey"))}
    />
    <Route
      path={`${match.url}/add-survey`}
      component={asyncComponent(() => import("./Survey/AddSurvey"))}
    />
    <Route
      path={`${match.url}/edit-survey/:id`}
      component={asyncComponent(() => import("./Survey/EditSurvey"))}
    />
    <Route
      path={`${match.url}/manage-survey/:id`}
      component={asyncComponent(() => import("./Survey/ManageSurvey"))}
    />

    <Route
      path={`${match.url}/survey/:s_id/report`}
      component={asyncComponent(() => import("./GeneratedReports"))}
    />

    <Route
      path={`${match.url}/survey/:s_id/manage`}
      component={asyncComponent(() => import("./Survey/ManageSurvey/Manage"))}
    />

    <Route
      path={`${match.url}/survey/:s_id/question_categories`}
      component={asyncComponent(() =>
        import("./Survey/ManageSurvey/QuestionCategories")
      )}
    />
    <Route
      path={`${match.url}/survey/:s_id/questions`}
      component={asyncComponent(() =>
        import("./Survey/ManageSurvey/Questions")
      )}
    />
    <Route
      path={`${match.url}/survey/:s_id/question/register`}
      component={asyncComponent(() =>
        import("./Survey/ManageSurvey/Questions/AddQuestions")
      )}
    />
    <Route
      path={`${match.url}/survey/:s_id/question/edit/:id`}
      component={asyncComponent(() =>
        import("./Survey/ManageSurvey/Questions/EditQuestions")
      )}
    />
    <Route
      path={`${match.url}/survey/:s_id/responses`}
      component={asyncComponent(() => import("./Survey/ManageSurvey/Response"))}
    />
    <Route
      path={`${match.url}/survey/:s_id/scheduled`}
      component={asyncComponent(() =>
        import("./Survey/ManageSurvey/ScheduledResponse")
      )}
    />
    <Route
      path={`${match.url}/survey/:s_id/attachments`}
      component={asyncComponent(() =>
        import("./Survey/ManageSurvey/Attachment")
      )}
    />
    <Route
      path={`${match.url}/survey/:s_id/participants`}
      component={asyncComponent(() =>
        import("./Survey/ManageSurvey/participant")
      )}
    />

    <Route
      path={`${match.url}/survey/:s_id/survey-parts/:sp_id/questions`}
      component={asyncComponent(() =>
        import("./Survey/ManageSurvey/SurveyParts/Questions")
      )}
    />
    <Route
      path={`${match.url}/survey/:s_id/survey-parts/:sp_id/question/register`}
      component={asyncComponent(() =>
        import("./Survey/ManageSurvey/SurveyParts/Questions/AddQuestions")
      )}
    />
    <Route
      path={`${match.url}/survey/:s_id/survey-parts/:sp_id/question/edit/:id`}
      component={asyncComponent(() =>
        import("./Survey/ManageSurvey/SurveyParts/Questions/EditQuestions")
      )}
    />

    <Route
      path={`${match.url}/survey/:s_id/survey-parts/:sp_id/question-categories`}
      component={asyncComponent(() =>
        import("./Survey/ManageSurvey/SurveyParts/QuestionCategories")
      )}
    />

    <Route
      path={`${match.url}/survey/:s_id/survey-parts`}
      component={asyncComponent(() =>
        import("./Survey/ManageSurvey/SurveyParts")
      )}
    />

    <Route
      path={`${match.url}/employee-surveys`}
      component={asyncComponent(() => import("./EmployeeSurvey"))}
    />
    <Route
      path={`${match.url}/add-employee-survey`}
      component={asyncComponent(() => import("./EmployeeSurvey/AddSurvey"))}
    />
    <Route
      path={`${match.url}/edit-employee-survey/:id`}
      component={asyncComponent(() => import("./EmployeeSurvey/EditSurvey"))}
    />
    <Route
      path={`${match.url}/manage-employee-survey/:id`}
      component={asyncComponent(() => import("./EmployeeSurvey/ManageSurvey"))}
    />

    <Route
      path={`${match.url}/employee-survey/:s_id/report`}
      component={asyncComponent(() =>
        import("./EmployeeSurvey/GeneratedReports")
      )}
    />

    <Route
      path={`${match.url}/employee-survey/:s_id/manage`}
      component={asyncComponent(() =>
        import("./EmployeeSurvey/ManageSurvey/Manage")
      )}
    />

    <Route
      path={`${match.url}/employee-survey/:s_id/question-categories`}
      component={asyncComponent(() =>
        import("./EmployeeSurvey/ManageSurvey/QuestionCategories")
      )}
    />
    <Route
      path={`${match.url}/employee-survey/:s_id/questions`}
      component={asyncComponent(() =>
        import("./EmployeeSurvey/ManageSurvey/Questions")
      )}
    />
    <Route
      path={`${match.url}/employee-survey/:s_id/question/register`}
      component={asyncComponent(() =>
        import("./EmployeeSurvey/ManageSurvey/Questions/AddQuestions")
      )}
    />
    <Route
      path={`${match.url}/employee-survey/:s_id/question/edit/:id`}
      component={asyncComponent(() =>
        import("./EmployeeSurvey/ManageSurvey/Questions/EditQuestions")
      )}
    />
    <Route
      path={`${match.url}/employee-survey/:s_id/responses`}
      component={asyncComponent(() =>
        import("./EmployeeSurvey/ManageSurvey/Response")
      )}
    />
    <Route
      path={`${match.url}/employee-survey/:s_id/attachments`}
      component={asyncComponent(() =>
        import("./EmployeeSurvey/ManageSurvey/Attachment")
      )}
    />
    <Route
      path={`${match.url}/employee-survey/:s_id/participants`}
      component={asyncComponent(() =>
        import("./EmployeeSurvey/ManageSurvey/participant")
      )}
    />

    <Route
      path={`${match.url}/employee-survey/:s_id/survey-parts/:sp_id/questions`}
      component={asyncComponent(() =>
        import("./EmployeeSurvey/ManageSurvey/SurveyParts/Questions")
      )}
    />
    <Route
      path={`${match.url}/employee-survey/:s_id/survey-parts/:sp_id/question/register`}
      component={asyncComponent(() =>
        import(
          "./EmployeeSurvey/ManageSurvey/SurveyParts/Questions/AddQuestions"
        )
      )}
    />
    <Route
      path={`${match.url}/employee-survey/:s_id/survey-parts/:sp_id/question/edit/:id`}
      component={asyncComponent(() =>
        import(
          "./EmployeeSurvey/ManageSurvey/SurveyParts/Questions/EditQuestions"
        )
      )}
    />

    <Route
      path={`${match.url}/employee-survey/:s_id/survey-parts/:sp_id/question-categories`}
      component={asyncComponent(() =>
        import("./EmployeeSurvey/ManageSurvey/SurveyParts/QuestionCategories")
      )}
    />

    <Route
      path={`${match.url}/employee-survey/:s_id/survey-parts`}
      component={asyncComponent(() =>
        import("./EmployeeSurvey/ManageSurvey/SurveyParts")
      )}
    />

    {/* routes under income category */}
    <Route
      path={`${match.url}/agriculture_types`}
      component={asyncComponent(() => import("./AgricultureTypes"))}
    />
    <Route
      path={`${match.url}/add-agriculture_types`}
      component={asyncComponent(() =>
        import("./AgricultureTypes/AddAgricultureTypes")
      )}
    />
    <Route
      path={`${match.url}/edit-agriculture_types/:id`}
      component={asyncComponent(() =>
        import("./AgricultureTypes/EditAgricultureTypes")
      )}
    />

    <Route
      path={`${match.url}/employment_types`}
      component={asyncComponent(() => import("./EmploymentTypes"))}
    />
    <Route
      path={`${match.url}/business_types`}
      component={asyncComponent(() => import("./BusinessTypes"))}
    />
    <Route
      path={`${match.url}/non_work_income_types`}
      component={asyncComponent(() => import("./NoWorkIncomeTypes"))}
    />

    <Route
      path={`${match.url}/income/data`}
      component={asyncComponent(() => import("./Incomes/Data"))}
    />

    <Route
      path={`${match.url}/income/scheduled_data`}
      component={asyncComponent(() => import("./Incomes/ScheduledData"))}
    />

    <Route
      path={`${match.url}/income/reports`}
      component={asyncComponent(() => import("./Incomes/GeneratedReports"))}
    />

    {/* routes under income category */}
    <Route
      path={`${match.url}/expense_types`}
      component={asyncComponent(() => import("./ExpenseTypes"))}
    />

    <Route
      path={`${match.url}/expenses/data`}
      component={asyncComponent(() => import("./Expenses/Data"))}
    />

    <Route
      path={`${match.url}/expenses/scheduled_data`}
      component={asyncComponent(() => import("./Expenses/ScheduledData"))}
    />

    <Route
      path={`${match.url}/expenses/reports`}
      component={asyncComponent(() => import("./Expenses/GeneratedReports"))}
    />

    <Route
      path={`${match.url}/cost_types`}
      component={asyncComponent(() => import("./CostTypes"))}
    />

    {/* routes under income category */}
    <Route
      path={`${match.url}/saving_tools`}
      component={asyncComponent(() => import("./SavingTools"))}
    />

    <Route
      path={`${match.url}/savings/data`}
      component={asyncComponent(() => import("./Savings/Data"))}
    />

    <Route
      path={`${match.url}/savings/scheduled_data`}
      component={asyncComponent(() => import("./Savings/ScheduledData"))}
    />

    <Route
      path={`${match.url}/savings/reports`}
      component={asyncComponent(() => import("./Savings/GeneratedReports"))}
    />

    <Route
      path={`${match.url}/financial/data`}
      component={asyncComponent(() => import("./FinDataExport/Data"))}
    />

    <Route
      path={`${match.url}/asset_types`}
      component={asyncComponent(() => import("./AssetTypes"))}
    />

    <Route
      path={`${match.url}/assets/data`}
      component={asyncComponent(() => import("./Assets/Data"))}
    />

    <Route
      path={`${match.url}/assets/scheduled_data`}
      component={asyncComponent(() => import("./Assets/ScheduledData"))}
    />

    <Route
      path={`${match.url}/assets/reports`}
      component={asyncComponent(() => import("./Assets/GeneratedReports"))}
    />

    <Route
      path={`${match.url}/notification-new`}
      component={asyncComponent(() => import("./NotificationM"))}
    />

    {/* routes under income category */}
    <Route
      path={`${match.url}/loan_tools`}
      component={asyncComponent(() => import("./LoanTools"))}
    />

    <Route
      path={`${match.url}/credits/data`}
      component={asyncComponent(() => import("./Credits/Data"))}
    />

    <Route
      path={`${match.url}/credits/scheduled_data`}
      component={asyncComponent(() => import("./Credits/ScheduledData"))}
    />

    <Route
      path={`${match.url}/credits/reports`}
      component={asyncComponent(() => import("./Credits/GeneratedReports"))}
    />

    <Route
      path={`${match.url}/notifications`}
      component={asyncComponent(() => import("./Notification"))}
    />
    <Route
      path={`${match.url}/add-notification`}
      component={asyncComponent(() => import("./Notification/AddNotification"))}
    />
    <Route
      path={`${match.url}/edit-notification/:id`}
      component={asyncComponent(() =>
        import("./Notification/EditNotification")
      )}
    />
    <Route
      path={`${match.url}/translation`}
      component={asyncComponent(() => import("./Translations"))}
    />
    <Route
      path={`${match.url}/profile`}
      component={asyncComponent(() => import("./Profile"))}
    />
  </Switch>
);

export default Components;
