// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";
export const SWITCH_CURRENT_LANGUAGE = "SWITCH_CURRENT_LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";
export const USER_DATA = "user_data";
export const USER_TOKEN_SET = "user_token_set";
export const USER_ROLE_SET = "user_role_set";
export const GET_TRANSLATION = "GET_TRANSLATION"

//Sticky
export const GET_STICKY = "GET_STICKY";
export const NOTES_UPDATE = "NOTES_UPDATE";
export const FETCH_ALL_NOTES_SUCCESS = "FETCH_ALL_NOTES_SUCCESS";
export const UPDATE_ALL_NOTES_SUCCESS = "UPDATE_ALL_NOTES_SUCCESS";

//Contact
export const GET_All_CONTACT_SUCCESS = "GET_All_CONTACT_SUCCESS";
export const GET_All_USER_SUCCESS = "GET_All_USER_SUCCESS";
export const ON_ADD_CONTACT_SUCCESS = "ON_ADD_CONTACT_SUCCESS";
export const ON_ADD_USER_SUCCESS = "ON_ADD_USER_SUCCESS";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const GET_All_RESEARCHERS_SUCCESS = "GET_All_RESEARCHERS_SUCCESS";
export const GET_All_SURVEYORS_SUCCESS = "GET_All_SURVEYORS_SUCCESS";
export const GET_All_PROJECT_SURVEYORS_SUCCESS = "GET_All_PROJECT_SURVEYORS_SUCCESS";
export const GET_All_ALL_SURVEYORS_SUCCESS = "GET_All_ALL_SURVEYORS_SUCCESS";
export const ON_ADD_SURVEYORS_SUCCESS = "ON_ADD_SURVEYORS_SUCCESS";
export const DELETE_SURVEYORS_SUCCESS = "DELETE_SURVEYORS_SUCCESS";
export const GET_SURVEY_SUCCESS = "GET_SURVEY_SUCCESS";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";

export const GET_P_All_RESEARCHERS_SUCCESS = "GET_P_All_RESEARCHERS_SUCCESS";
export const GET_P_All_SURVEYORS_SUCCESS = "GET_P_All_SURVEYORS_SUCCESS";
export const GET_P_All_PARTICIPANTS_SUCCESS = "GET_P_All_PARTICIPANTS_SUCCESS";
export const GET_P_All_PROJECT_PARTICIPANTS_SUCCESS = "GET_P_All_PROJECT_PARTICIPANTS_SUCCESS";
export const GET_P_SURVEY_SUCCESS = "GET_P_SURVEY_SUCCESS";
export const GET_P_PROJECT_SUCCESS = "GET_P_PROJECT_SUCCESS";
export const DELETE_PARTICIPANT_SUCCESS = "DELETE_PARTICIPANT_SUCCESS";
export const ON_ADD_PARTICIPANT_SUCCESS = "ON_ADD_PARTICIPANT_SUCCESS";
export const ON_UPDATE_PARTICIPANT_SUCCESS = "ON_UPDATE_PARTICIPANT_SUCCESS";
export const GET_SURVEY_PARTS_SUCCESS = "GET_SURVEY_PARTS_SUCCESS";
export const GET_S_SURVEY_SUCCESS = "GET_S_SURVEY_SUCCESS";
export const ON_UPDATE_SURVEY_PART_SUCCESS = "ON_UPDATE_SURVEY_PART_SUCCESS";
export const GET_SURVEY_PART_SUCCESS = "GET_SURVEY_PART_SUCCESS";
export const GET_QUESTION_CATEGORIES_SUCCESS =
  "GET_QUESTION_CATEGORIES_SUCCESS";

export const ON_ADD_QUESTION_CAATEGORY = "ON_ADD_QUESTION_CAATEGORY";
export const ON_UPDATE_QUESTION_CAATEGORY = "ON_UPDATE_QUESTION_CAATEGORY";
export const ON_DELETE_QUESTION_CATEGORY_SUCCESS =
  "ON_DELETE_QUESTION_CATEGORY_SUCCESS";

export const GET_ME_SUCCESS = "GET_ME_SUCCESS";
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS";
export const GET_QUESTIONS_CATEGORY_SUCCESS = "GET_QUESTIONS_CATEGORY_SUCCESS";
export const GET_EXPORT_SUCCESS = "GET_EXPORT_SUCCESS";
export const GET_ATTACHMENT_EXPORT_SUCCESS = "GET_ATTACHMENT_EXPORT_SUCCESS";
export const GET_CHECK_EXPORT_SUCCESS = "GET_CHECK_EXPORT_SUCCESS";
export const GET_ATTACHMENT_CHECK_EXPORT_SUCCESS = "GET_ATTACHMENT_CHECK_EXPORT_SUCCESS";
export const GET_FILTERED_RESEARCHERS_SUCCESS =
  "GET_FILTERED_RESEARCHERS_SUCCESS";

export const GET_INCOME_EXPORT_SUCCESS = "GET_INCOME_EXPORT_SUCCESS";
export const GET_INCOME_CHECK_EXPORT_SUCCESS = "GET_INCOME_CHECK_EXPORT_SUCCESS";

export const GET_SAVING_EXPORT_SUCCESS = "GET_SAVING_EXPORT_SUCCESS";
export const GET_SAVING_CHECK_EXPORT_SUCCESS = "GET_SAVING_CHECK_EXPORT_SUCCESS";

export const GET_NOTIFICATION_EXPORT_SUCCESS = "GET_NOTIFICATION_EXPORT_SUCCESS";
export const GET_NOTIFICATION_CHECK_EXPORT_SUCCESS = "GET_NOTIFICATION_CHECK_EXPORT_SUCCESS";

export const GET_EXPENSE_EXPORT_SUCCESS = "GET_EXPENSE_EXPORT_SUCCESS";
export const GET_EXPENSE_CHECK_EXPORT_SUCCESS = "GET_EXPENSE_CHECK_EXPORT_SUCCESS";
export const GET_ASSETS_EXPORT_SUCCESS = "GET_ASSETS_EXPORT_SUCCESS";
export const GET_ASSETS_CHECK_EXPORT_SUCCESS = "GET_ASSETS_CHECK_EXPORT_SUCCESS";

export const GET_EMPLOYEE_EXPORT_SUCCESS = "GET_EMPLOYEE_EXPORT_SUCCESS";
export const GET_EMPLOYEE_CHECK_EXPORT_SUCCESS = "GET_EMPLOYEE_CHECK_EXPORT_SUCCESS";
export const GET_CREDIT_EXPORT_SUCCESS = "GET_CREDIT_EXPORT_SUCCESS";
export const GET_CREDIT_CHECK_EXPORT_SUCCESS = "GET_CREDIT_CHECK_EXPORT_SUCCESS";
export const RESET_CREDIT_EXPORT_SUCCESS = "RESET_CREDIT_EXPORT_SUCCESS";
export const GET_LOAN_GIVEN_EXPORT_SUCCESS = "GET_LOAN_GIVEN_EXPORT_SUCCESS";
export const GET_LOAN_GIVEN_CHECK_EXPORT_SUCCESS = "GET_LOAN_GIVEN_CHECK_EXPORT_SUCCESS";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const GET_LOAN_TAKEN_EXPORT_SUCCESS = "GET_LOAN_TAKEN_EXPORT_SUCCESS";
export const GET_LOAN_TAKEN_CHECK_EXPORT_SUCCESS = "GET_LOAN_TAKEN_CHECK_EXPORT_SUCCESS";
export const RESET_ASSETS_EXPORT_SUCCESS = "RESET_ASSETS_EXPORT_SUCCESS";
export const RESET_LOAN_GIVEN_EXPORT_SUCCESS = "RESET_LOAN_GIVEN_EXPORT_SUCCESS";
export const RESET_LOAN_TAKEN_EXPORT_SUCCESS = "RESET_LOAN_TAKEN_EXPORT_SUCCESS";
export const RESET_SAVING_EXPORT_SUCCESS = "RESET_SAVING_EXPORT_SUCCESS";
export const RESET_INCOME_EXPORT_SUCCESS = "RESET_INCOME_EXPORT_SUCCESS";
export const RESET_EXPENSE_EXPORT_SUCCESS = "RESET_EXPENSE_EXPORT_SUCCESS";
export const RESET_EMPLOYEE_EXPORT_SUCCESS = "RESET_EMPLOYEE_EXPORT_SUCCESS";
export const RESET_SURVEY_EXPORT_SUCCESS = "RESET_SURVEY_EXPORT_SUCCESS";
export const RESET_NOTIFICATION_EXPORT_SUCCESS = "RESET_NOTIFICATION_EXPORT_SUCCESS";
export const RESET_SURVEY_ATTACHMENT_EXPORT_SUCCESS = "RESET_SURVEY_ATTACHMENT_EXPORT_SUCCESS";

export const GET_FINANCE_EXPORT_SUCCESS = "GET_FINANCE_EXPORT_SUCCESS";
export const GET_FINANCE_CHECK_EXPORT_SUCCESS = "GET_FINANCE_CHECK_EXPORT_SUCCESS";

export const GET_STAT_SUCCESS = "GET_STAT_SUCCESS";

export const GET_All_ADMIN_USER_SUCCESS = "GET_All_ADMIN_USER_SUCCESS";
export const ON_ADD_ADMIN_USER_SUCCESS = "ON_ADD_ADMIN_USER_SUCCESS";
export const DELETE_ADMIN_USER_SUCCESS = "DELETE_ADMIN_USER_SUCCESS";
export const UPDATE_ADMIN_USER_SUCCESS = "UPDATE_ADMIN_USER_SUCCESS";

export const GET_All_LENDER_USER_SUCCESS = "GET_All_LENDER_USER_SUCCESS";
export const ON_ADD_LENDER_USER_SUCCESS = "ON_ADD_LENDER_USER_SUCCESS";
export const DELETE_LENDER_USER_SUCCESS = "DELETE_LENDER_USER_SUCCESS";
export const UPDATE_LENDER_USER_SUCCESS = "UPDATE_LENDER_USER_SUCCESS";

export const GET_All_PORTAL_USER_SUCCESS = "GET_All_PORTAL_USER_SUCCESS";
export const ON_ADD_PORTAL_USER_SUCCESS = "ON_ADD_PORTAL_USER_SUCCESS";
export const DELETE_PORTAL_USER_SUCCESS = "DELETE_PORTAL_USER_SUCCESS";
export const UPDATE_PORTAL_USER_SUCCESS = "UPDATE_PORTAL_USER_SUCCESS";

export const GET_All_RESEARCHER_USER_SUCCESS = "GET_All_RESEARCHER_USER_SUCCESS";
export const ON_ADD_RESEARCHER_USER_SUCCESS = "ON_ADD_RESEARCHER_USER_SUCCESS";
export const DELETE_RESEARCHER_USER_SUCCESS = "DELETE_RESEARCHER_USER_SUCCESS";
export const UPDATE_RESEARCHER_USER_SUCCESS = "UPDATE_RESEARCHER_USER_SUCCESS";

export const GET_All_INSTITUTION_USER_SUCCESS = "GET_All_INSTITUTION_USER_SUCCESS";
export const ON_ADD_INSTITUTION_USER_SUCCESS = "ON_ADD_INSTITUTION_USER_SUCCESS";
export const DELETE_INSTITUTION_USER_SUCCESS = "DELETE_INSTITUTION_USER_SUCCESS";
export const UPDATE_INSTITUTION_USER_SUCCESS = "UPDATE_INSTITUTION_USER_SUCCESS";

export const GET_All_CLIENT_USER_SUCCESS = "GET_All_CLIENT_USER_SUCCESS";
export const ON_ADD_CLIENT_USER_SUCCESS = "ON_ADD_CLIENT_USER_SUCCESS";
export const DELETE_CLIENT_USER_SUCCESS = "DELETE_CLIENT_USER_SUCCESS";
export const UPDATE_CLIENT_USER_SUCCESS = "UPDATE_CLIENT_USER_SUCCESS";

export const GET_All_SURVEYOR_USER_SUCCESS = "GET_All_SURVEYOR_USER_SUCCESS";
export const ON_ADD_SURVEYOR_USER_SUCCESS = "ON_ADD_SURVEYOR_USER_SUCCESS";
export const DELETE_SURVEYOR_USER_SUCCESS = "DELETE_SURVEYOR_USER_SUCCESS";
export const UPDATE_SURVEYOR_USER_SUCCESS = "UPDATE_SURVEYOR_USER_SUCCESS";

export const GET_All_EMPLOYER_USER_SUCCESS = "GET_All_EMPLOYER_USER_SUCCESS";
export const ON_ADD_EMPLOYER_USER_SUCCESS = "ON_ADD_EMPLOYER_USER_SUCCESS";
export const DELETE_EMPLOYER_USER_SUCCESS = "DELETE_EMPLOYER_USER_SUCCESS";
export const UPDATE_EMPLOYER_USER_SUCCESS = "UPDATE_EMPLOYER_USER_SUCCESS";

export const GET_All_DEMOGRAPHIC_SUCCESS = "GET_All_DEMOGRAPHIC_SUCCESS";
export const ON_ADD_DEMOGRAPHIC_SUCCESS = "ON_ADD_DEMOGRAPHIC_SUCCESS";
export const DELETE_DEMOGRAPHIC_SUCCESS = "DELETE_DEMOGRAPHIC_SUCCESS";
export const UPDATE_DEMOGRAPHIC_SUCCESS = "UPDATE_DEMOGRAPHIC_SUCCESS";

export const GET_All_EMPLOYEE_FORM_SUCCESS = "GET_All_EMPLOYEE_FORM_SUCCESS";
export const ON_ADD_EMPLOYEE_FORM_SUCCESS = "ON_ADD_EMPLOYEE_FORM_SUCCESS";
export const DELETE_EMPLOYEE_FORM_SUCCESS = "DELETE_EMPLOYEE_FORM_SUCCESS";
export const UPDATE_EMPLOYEE_FORM_SUCCESS = "UPDATE_EMPLOYEE_FORM_SUCCESS";

export const GET_All_EMPLOYMENT_QUESTION_SUCCESS = "GET_All_EMPLOYMENT_QUESTION_SUCCESS";
export const ON_ADD_EMPLOYMENT_QUESTION_SUCCESS = "ON_ADD_EMPLOYMENT_QUESTION_SUCCESS";
export const DELETE_EMPLOYMENT_QUESTION_SUCCESS = "DELETE_EMPLOYMENT_QUESTION_SUCCESS";
export const UPDATE_EMPLOYMENT_QUESTION_SUCCESS = "UPDATE_EMPLOYMENT_QUESTION_SUCCESS";

export const GET_All_LOAN_QUESTION_SUCCESS = "GET_All_LOAN_QUESTION_SUCCESS";
export const ON_ADD_LOAN_QUESTION_SUCCESS = "ON_ADD_LOAN_QUESTION_SUCCESS";
export const DELETE_LOAN_QUESTION_SUCCESS = "DELETE_LOAN_QUESTION_SUCCESS";
export const UPDATE_LOAN_QUESTION_SUCCESS = "UPDATE_LOAN_QUESTION_SUCCESS";

export const GET_All_NOTIFICATION_SUCCESS = "GET_All_NOTIFICATION_SUCCESS";
export const ON_ADD_NOTIFICATION_SUCCESS = "ON_ADD_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";

export const GET_All_ASSET_TYPE_SUCCESS = "GET_All_ASSET_TYPE_SUCCESS";
export const ON_ADD_ASSET_TYPE_SUCCESS = "ON_ADD_ASSET_TYPE_SUCCESS";
export const DELETE_ASSET_TYPE_SUCCESS = "DELETE_ASSET_TYPE_SUCCESS";
export const UPDATE_ASSET_TYPE_SUCCESS = "UPDATE_ASSET_TYPE_SUCCESS";

export const GET_All_AGRICULTURE_TYPE_SUCCESS = "GET_All_AGRICULTURE_TYPE_SUCCESS";
export const ON_ADD_AGRICULTURE_TYPE_SUCCESS = "ON_ADD_AGRICULTURE_TYPE_SUCCESS";
export const DELETE_AGRICULTURE_TYPE_SUCCESS = "DELETE_AGRICULTURE_TYPE_SUCCESS";
export const UPDATE_AGRICULTURE_TYPE_SUCCESS = "UPDATE_AGRICULTURE_TYPE_SUCCESS";

export const GET_All_BUSINESS_TYPE_SUCCESS = "GET_All_BUSINESS_TYPE_SUCCESS";
export const ON_ADD_BUSINESS_TYPE_SUCCESS = "ON_ADD_BUSINESS_TYPE_SUCCESS";
export const DELETE_BUSINESS_TYPE_SUCCESS = "DELETE_BUSINESS_TYPE_SUCCESS";
export const UPDATE_BUSINESS_TYPE_SUCCESS = "UPDATE_BUSINESS_TYPE_SUCCESS";

export const GET_All_EMPLOYMENT_TYPE_SUCCESS = "GET_All_EMPLOYMENT_TYPE_SUCCESS";
export const ON_ADD_EMPLOYMENT_TYPE_SUCCESS = "ON_ADD_EMPLOYMENT_TYPE_SUCCESS";
export const DELETE_EMPLOYMENT_TYPE_SUCCESS = "DELETE_EMPLOYMENT_TYPE_SUCCESS";
export const UPDATE_EMPLOYMENT_TYPE_SUCCESS = "UPDATE_EMPLOYMENT_TYPE_SUCCESS";

export const GET_All_OWNERSHIP_TYPE_SUCCESS = "GET_All_OWNERSHIP_TYPE_SUCCESS";
export const ON_ADD_OWNERSHIP_TYPE_SUCCESS = "ON_ADD_OWNERSHIP_TYPE_SUCCESS";
export const DELETE_OWNERSHIP_TYPE_SUCCESS = "DELETE_OWNERSHIP_TYPE_SUCCESS";
export const UPDATE_OWNERSHIP_TYPE_SUCCESS = "UPDATE_OWNERSHIP_TYPE_SUCCESS";

export const GET_All_NON_WORK_INCOME_TYPE_SUCCESS = "GET_All_NON_WORK_INCOME_TYPE_SUCCESS";
export const ON_ADD_NON_WORK_INCOME_TYPE_SUCCESS = "ON_ADD_NON_WORK_INCOME_TYPE_SUCCESS";
export const DELETE_NON_WORK_INCOME_TYPE_SUCCESS = "DELETE_NON_WORK_INCOME_TYPE_SUCCESS";
export const UPDATE_NON_WORK_INCOME_TYPE_SUCCESS = "UPDATE_NON_WORK_INCOME_TYPE_SUCCESS";

export const GET_All_EXPENSE_TYPE_SUCCESS = "GET_All_EXPENSE_TYPE_SUCCESS";
export const ON_ADD_EXPENSE_TYPE_SUCCESS = "ON_ADD_EXPENSE_TYPE_SUCCESS";
export const DELETE_EXPENSE_TYPE_SUCCESS = "DELETE_EXPENSE_TYPE_SUCCESS";
export const UPDATE_EXPENSE_TYPE_SUCCESS = "UPDATE_EXPENSE_TYPE_SUCCESS";

export const GET_All_COST_TYPE_SUCCESS = "GET_All_COST_TYPE_SUCCESS";
export const ON_ADD_COST_TYPE_SUCCESS = "ON_ADD_COST_TYPE_SUCCESS";
export const DELETE_COST_TYPE_SUCCESS = "DELETE_COST_TYPE_SUCCESS";
export const UPDATE_COST_TYPE_SUCCESS = "UPDATE_COST_TYPE_SUCCESS";

export const GET_All_SAVING_TOOLE_SUCCESS = "GET_All_SAVING_TOOLE_SUCCESS";
export const ON_ADD_SAVING_TOOL_SUCCESS = "ON_ADD_SAVING_TOOL_SUCCESS";
export const DELETE_SAVING_TOOL_SUCCESS = "DELETE_SAVING_TOOL_SUCCESS";
export const UPDATE_SAVING_TOOL_SUCCESS = "UPDATE_SAVING_TOOL_SUCCESS";

export const GET_All_LOAN_TOOLE_SUCCESS = "GET_All_LOAN_TOOLE_SUCCESS";
export const ON_ADD_LOAN_TOOL_SUCCESS = "ON_ADD_LOAN_TOOL_SUCCESS";
export const DELETE_LOAN_TOOL_SUCCESS = "DELETE_LOAN_TOOL_SUCCESS";
export const UPDATE_LOAN_TOOL_SUCCESS = "UPDATE_LOAN_TOOL_SUCCESS";

export const GET_All_LANGUAGE_SUCCESS = "GET_All_LANGUAGE_SUCCESS";
export const ON_ADD_LANGUAGE_SUCCESS = "ON_ADD_LANGUAGE_SUCCESS";
export const DELETE_LANGUAGE_SUCCESS = "DELETE_LANGUAGE_SUCCESS";
export const UPDATE_LANGUAGE_SUCCESS = "UPDATE_LANGUAGE_SUCCESS";

export const GET_All_COMBINED_REPORT_DATA_SUCCESS = "GET_All_COMBINED_REPORT_DATA_SUCCESS";
export const GET_All_USER_SPECIFIC_REPORT_DATA_SUCCESS = "GET_All_USER_SPECIFIC_REPORT_DATA_SUCCESS";

export const GET_All_SURVEY_DATA_SUCCESS = "GET_All_SURVEY_DATA_SUCCESS";
export const ON_ADD_SURVEY_DATA_SUCCESS = "ON_ADD_SURVEY_DATA_SUCCESS";
export const DELETE_SURVEY_DATA_SUCCESS = "DELETE_SURVEY_DATA_SUCCESS";
export const UPDATE_SURVEY_DATA_SUCCESS = "UPDATE_SURVEY_DATA_SUCCESS";

export const GET_All_EMPLOYEE_SURVEY_DATA_SUCCESS = "GET_All_EMPLOYEE_SURVEY_DATA_SUCCESS";
export const ON_ADD_EMPLOYEE_SURVEY_DATA_SUCCESS = "ON_ADD_EMPLOYEE_SURVEY_DATA_SUCCESS";
export const DELETE_EMPLOYEE_SURVEY_DATA_SUCCESS = "DELETE_EMPLOYEE_SURVEY_DATA_SUCCESS";
export const UPDATE_EMPLOYEE_SURVEY_DATA_SUCCESS = "UPDATE_EMPLOYEE_SURVEY_DATA_SUCCESS";

export const GET_All_PROJECT_SUCCESS = "GET_All_PROJECT_SUCCESS";
export const ON_ADD_PROJECT_SUCCESS = "ON_ADD_PROJECT_SUCCESS";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";

// App settings
export const GET_DEFAULT_APP_SETTINGS_SUCCESS = "GET_DEFAULT_APP_SETTINGS_SUCCESS";
export const GET_APP_SETTINGS_SUCCESS = "GET_APP_SETTINGS_SUCCESS";
export const ON_ADD_APP_SETTINGS_SUCCESS = "ON_ADD_APP_SETTINGS_SUCCESS";
export const DELETE_APP_SETTINGS_SUCCESS = "DELETE_APP_SETTINGS_SUCCESS";
export const UPDATE_APP_SETTINGS_SUCCESS = "UPDATE_APP_SETTINGS_SUCCESS";
export const SET_SELECTED_APP_SETTING = "SET_SELECTED_APP_SETTING";

// Portal settings
export const GET_PORTAL_SETTINGS_SUCCESS = "GET_PORTAL_SETTINGS_SUCCESS";
export const ON_UPDATE_PORTAL_SETTINGS_SUCCESS = "ON_UPDATE_PORTAL_SETTINGS_SUCCESS";

//Permissions
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";
export const ON_ADD_PERMISSIONS_SUCCESS = "ON_ADD_PERMISSIONS_SUCCESS";
export const DELETE_PERMISSIONS_SUCCESS = "DELETE_PERMISSIONS_SUCCESS";
export const UPDATE_PERMISSIONS_SUCCESS = "UPDATE_PERMISSIONS_SUCCESS";

export const GET_All_QUESTION_CATEGORY_SUCCESS = "GET_All_QUESTION_CATEGORY_SUCCESS";
export const ON_ADD_QUESTION_CATEGORY_SUCCESS = "ON_ADD_QUESTION_CATEGORY_SUCCESS";
export const DELETE_QUESTION_CATEGORY_SUCCESS = "DELETE_QUESTION_CATEGORY_SUCCESS";
export const UPDATE_QUESTION_CATEGORY_SUCCESS = "UPDATE_QUESTION_CATEGORY_SUCCESS";

export const GET_All_EMPLOYEE_QUESTION_CATEGORY_SUCCESS = "GET_All_EMPLOYEE_QUESTION_CATEGORY_SUCCESS";
export const ON_ADD_EMPLOYEE_QUESTION_CATEGORY_SUCCESS = "ON_ADD_EMPLOYEE_QUESTION_CATEGORY_SUCCESS";
export const DELETE_EMPLOYEE_QUESTION_CATEGORY_SUCCESS = "DELETE_EMPLOYEE_QUESTION_CATEGORY_SUCCESS";
export const UPDATE_EMPLOYEE_QUESTION_CATEGORY_SUCCESS = "UPDATE_EMPLOYEE_QUESTION_CATEGORY_SUCCESS";

export const GET_All_RECURRING_QUESTION_SUCCESS = "GET_All_RECURRING_QUESTION_SUCCESS";
export const ON_ADD_RECURRING_QUESTION_SUCCESS = "ON_ADD_RECURRING_QUESTION_SUCCESS";
export const DELETE_RECURRING_QUESTION_SUCCESS = "DELETE_RECURRING_QUESTION_SUCCESS";
export const UPDATE_RECURRING_QUESTION_SUCCESS = "UPDATE_RECURRING_QUESTION_SUCCESS";

export const GET_All_EMPLOYEE_RECURRING_QUESTION_SUCCESS = "GET_All_EMPLOYEE_RECURRING_QUESTION_SUCCESS";
export const ON_ADD_EMPLOYEE_RECURRING_QUESTION_SUCCESS = "ON_ADD_EMPLOYEE_RECURRING_QUESTION_SUCCESS";
export const DELETE_EMPLOYEE_RECURRING_QUESTION_SUCCESS = "DELETE_EMPLOYEE_RECURRING_QUESTION_SUCCESS";
export const UPDATE_EMPLOYEE_RECURRING_QUESTION_SUCCESS = "UPDATE_EMPLOYEE_RECURRING_QUESTION_SUCCESS";

export const GET_All_E_EMPLOYEE_RECURRING_QUESTION_SUCCESS = "GET_All_E_EMPLOYEE_RECURRING_QUESTION_SUCCESS";
export const ON_ADD_E_EMPLOYEE_RECURRING_QUESTION_SUCCESS = "ON_ADD_E_EMPLOYEE_RECURRING_QUESTION_SUCCESS";
export const DELETE_E_EMPLOYEE_RECURRING_QUESTION_SUCCESS = "DELETE_E_EMPLOYEE_RECURRING_QUESTION_SUCCESS";
export const UPDATE_E_EMPLOYEE_RECURRING_QUESTION_SUCCESS = "UPDATE_E_EMPLOYEE_RECURRING_QUESTION_SUCCESS";

export const GET_All_SURVEY_PART_SUCCESS = "GET_All_SURVEY_PART_SUCCESS";
export const GET_All_EMPLOYEE_SURVEY_PART_SUCCESS = "GET_All_EMPLOYEE_SURVEY_PART_SUCCESS";

export const GET_All_SURVEY_PART_CATEGORY_SUCCESS = "GET_All_SURVEY_PART_CATEGORY_SUCCESS";
export const ON_ADD_SURVEY_PART_CATEGORY_SUCCESS = "ON_ADD_SURVEY_PART_CATEGORY_SUCCESS";
export const DELETE_SURVEY_PART_CATEGORY_SUCCESS = "DELETE_SURVEY_PART_CATEGORY_SUCCESS";
export const UPDATE_SURVEY_PART_CATEGORY_SUCCESS = "UPDATE_SURVEY_PART_CATEGORY_SUCCESS";

export const GET_All_EMPLOYEE_SURVEY_PART_CATEGORY_SUCCESS = "GET_All_EMPLOYEE_SURVEY_PART_CATEGORY_SUCCESS";
export const ON_ADD_EMPLOYEE_SURVEY_PART_CATEGORY_SUCCESS = "ON_ADD_EMPLOYEE_SURVEY_PART_CATEGORY_SUCCESS";
export const DELETE_EMPLOYEE_SURVEY_PART_CATEGORY_SUCCESS = "DELETE_EMPLOYEE_SURVEY_PART_CATEGORY_SUCCESS";
export const UPDATE_EMPLOYEE_SURVEY_PART_CATEGORY_SUCCESS = "UPDATE_EMPLOYEE_SURVEY_PART_CATEGORY_SUCCESS";
export const GET_All_SURVEY_PART_QUESTION_SUCCESS = "GET_All_SURVEY_PART_QUESTION_SUCCESS";
export const ON_ADD_SURVEY_PART_QUESTION_SUCCESS = "ON_ADD_SURVEY_PART_QUESTION_SUCCESS";
export const DELETE_SURVEY_PART_QUESTION_SUCCESS = "DELETE_SURVEY_PART_QUESTION_SUCCESS";
export const UPDATE_SURVEY_PART_QUESTION_SUCCESS = "UPDATE_SURVEY_PART_QUESTION_SUCCESS";

export const GET_GENERATED_REPORTS_SUCCESS = "GET_GENERATED_REPORTS_SUCCESS";
export const GET_EMPLOYEE_SURVEY_GENERATED_REPORTS_SUCCESS = "GET_EMPLOYEE_SURVEY_GENERATED_REPORTS_SUCCESS";
export const GET_GENERATED_INCOME_REPORTS_SUCCESS = "GET_GENERATED_INCOME_REPORTS_SUCCESS";
export const GET_GENERATED_EXPENSE_REPORTS_SUCCESS = "GET_GENERATED_EXPENSE_REPORTS_SUCCESS";
export const GET_GENERATED_CREDIT_REPORTS_SUCCESS = "GET_GENERATED_CREDIT_REPORTS_SUCCESS";
export const GET_GENERATED_LOAN_GIVEN_REPORTS_SUCCESS = "GET_GENERATED_LOAN_GIVEN_REPORTS_SUCCESS";
export const GET_GENERATED_SAVING_REPORTS_SUCCESS = "GET_GENERATED_SAVING_REPORTS_SUCCESS";
export const GET_GENERATED_ASSET_REPORTS_SUCCESS = "GET_GENERATED_ASSET_REPORTS_SUCCESS";
export const GET_GENERATED_EMPLOYEE_REPORTS_SUCCESS = "GET_GENERATED_EMPLOYEE_REPORTS_SUCCESS";


// reorder questions action types
export const REORDER_QUESTIONS_REQUEST = 'REORDER_QUESTIONS_REQUEST';
export const REORDER_QUESTIONS_SUCCESS = 'REORDER_QUESTIONS_SUCCESS';
export const REORDER_QUESTIONS_FAILED = 'REORDER_QUESTIONS_FAILED';

// Assign App Setting to Researchers
export const ASSIGN_APP_SETTING = 'ASSIGN_APP_SETTING';

// Assign Default App Setting to Researchers
export const ASSIGN_DEFAULT_APP_SETTING = 'ASSIGN_DEFAULT_APP_SETTING';

// Assign Portal Setting to Researchers
export const ASSIGN_PORTAL_SETTING = 'ASSIGN_PORTAL_SETTING';

// Assign Default Portal Setting to Researchers
export const ASSIGN_DEFAULT_PORTAL_SETTING = 'ASSIGN_DEFAULT_PORTAL_SETTING';


// Assign Surveyor to Researchers
export const ASSIGN_SURVEYOR = 'ASSIGN_SURVEYOR';

// Administrator Permission Group Setting
export const ASSIGN_PERMISSION_GROUP = 'ASSIGN_PERMISSION_GROUP';

export const GET_All_EMPLOYEE_SURVEYORS_SUCCESS = 'GET_All_EMPLOYEE_SURVEYORS_SUCCESS';
export const GET_All_ALL_EMPLOYEE_SURVEYORS_SUCCESS = 'GET_All_ALL_EMPLOYEE_SURVEYORS_SUCCESS';
export const ON_ADD_EMPLOYEE_SURVEYORS_SUCCESS = 'ON_ADD_EMPLOYEE_SURVEYORS_SUCCESS';
export const DELETE_EMPLOYEE_SURVEYORS_SUCCESS = 'DELETE_EMPLOYEE_SURVEYORS_SUCCESS';
export const GET_EMPLOYEE_SURVEY_SUCCESS = 'GET_EMPLOYEE_SURVEY_SUCCESS';

export const GET_All_EMPLOYEE_PARTICIPANTS_SUCCESS = "GET_All_EMPLOYEE_PARTICIPANTS_SUCCESS";
export const GET_P_EMPLOYEE_SURVEY_SUCCESS = "GET_P_EMPLOYEE_SURVEY_SUCCESS";

export const GET_DEMOGRAPHICS_ORDER_SUCCESS = 'GET_DEMOGRAPHICS_ORDER_SUCCESS';
export const ON_ADD_DEMOGRAPHIC_ORDER_SUCCESS = 'ON_ADD_DEMOGRAPHIC_ORDER_SUCCESS';
export const UPDATE_DEMOGRAPHIC_ORDER_SUCCESS = "UPDATE_DEMOGRAPHIC_ORDER_SUCCESS";
export const GET_DOWNLOAD_LINK_SUCCESS = "GET_DOWNLOAD_LINK_SUCCESS";


