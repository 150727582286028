export const ADMINISTRATOR = "ADMINISTRATOR";
export const LENDER = "LENDER";
export const PORTAL_USER = "PORTAL_USER";
export const RESEARCHER = "RESEARCHER";
export const INSTITUTION = "INSTITUTION";
export const CLIENT = "CLIENT";
export const SURVEYOR = "SURVEYOR";
export const EMPLOYER = "EMPLOYER";
export const DEMOGRAPHIC = "DEMOGRAPHIC";
export const EMPLOYEE_FORM = "EMPLOYEE_FORM";
export const APP_SETTING = "APP_SETTING";
export const DEFAULT_APP_SETTING = "DEFAULT_APP_SETTING";
export const PORTAL_SETTING = "PORTAL_SETTING";
export const PERMISSIONS = "PERMISSIONS";
export const EMPLOYMENT_Q = "EMPLOYMENT_Q";
export const LOAN_Q = "LOAN_Q";
export const NOTIFICATION = "NOTIFICATION";
export const ASSET_TYPE = "ASSET_TYPE";
export const AGRICULTURE_TYPE = "AGRICULTURE_TYPE";
export const BUSINESS_TYPE = "BUSINESS_TYPE";
export const EMPLOYMENT_TYPE = "EMPLOYMENT_TYPE";
export const OWNERSHIP_TYPE = "OWNERSHIP_TYPE";
export const NON_WORK_INCOME_TYPE = "NON_WORK_INCOME_TYPE";
export const EXPENSE_TYPE = "EXPENSE_TYPE";
export const COST_TYPE = "COST_TYPE";
export const SAVING_TOOL = "SAVING_TOOL";
export const LOAN_TOOL = "LOAN_TOOL";
export const LANGUAGE = "LANGUAGE";
export const COMBINED = "COMBINED";
export const USER_SPECIFIC = "USER_SPECIFIC";
export const SURVEY = "SURVEY";
export const EMPLOYEE_SURVEY = "EMPLOYEE_SURVEY";
export const PROJECT = "PROJECT";
export const QUESTION_CATEGORY = "QUESTION_CATEGORY";
export const EMPLOYEE_QUESTION_CATEGORY = "EMPLOYEE_QUESTION_CATEGORY";
export const EMPLOYEE_SURVEY_PART_QUESTION_CATEGORY = "EMPLOYEE_SURVEY_PART_QUESTION_CATEGORY";
export const RECURRING_QUESTION = "RECURRING_QUESTION";
export const E_RECURRING_QUESTION = "E_RECURRING_QUESTION";
export const EMPLOYEE_RECURRING_QUESTION = "EMPLOYEE_RECURRING_QUESTION";
export const SURVEY_PART = "SURVEY_PART";
export const EMPLOYEE_SURVEY_PART = "EMPLOYEE_SURVEY_PART";
export const SURVEY_PART_CATEGORY = "SURVEY_PART_CATEGORY";
export const SURVEY_PART_QUESTION = "SURVEY_PART_QUESTION";
export const GENERATE_REPORT = "GENERATE_REPORT";
export const EMPLOYEE_SURVEY_GENERATED_REPORT = "EMPLOYEE_SURVEY_GENERATED_REPORT";
export const GENERATED_INCOME_REPORT = "GENERATED_INCOME_REPORT";
export const GENERATED_EXPENSE_REPORT = "GENERATED_EXPENSE_REPORT";
export const GENERATED_CREDIT_REPORT = "GENERATED_CREDIT_REPORT";
export const GENERATED_LOAN_GIVEN_REPORT = "GENERATED_LOAN_GIVEN_REPORT";
export const GENERATED_SAVING_REPORT = "GENERATED_SAVING_REPORT";
export const GENERATED_ASSET_REPORT = "GENERATED_ASSET_REPORT";
export const GENERATED_EMPLOYEE_REPORT = "GENERATED_EMPLOYEE_REPORT";
export const DEMOGRAPHIC_ORDER = "DEMOGRAPHIC_ORDER";
