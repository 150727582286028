const HasPermission = (authUser, key) => {
  if (!authUser) {
    return false;
  }
  if (authUser.profile.role === "client") {
    return true;
  } else if (
    authUser.profile.role === "admin" &&
    authUser.profile.user.is_staff
  ) {
    return true;
  }
  if (authUser.dashboard_setting === null) {
    return false;
  }
  let value = false;
  authUser.dashboard_setting.permissions.map((permission) => {
    if (permission.dashboard_setting_permission_key === key) {
      value = permission.value;
    }
  });

  return value;
};

export default HasPermission;
