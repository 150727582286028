import axios from 'axios';

export default axios.create({
  baseURL: "https://api.finbit.co/api/",
  // baseURL: "http://127.0.0.1:8000/api/",
  headers: {
    'Content-Type': 'application/json',
  }
});

// export const mainurl = "http://127.0.0.1:8000/api/"
export const mainurl = "https://api.finbit.co/api/"

