import { useSelector } from "react-redux";

const Translate = ({ id, default_value = "" }) => {
  const { current_language, translation } = useSelector(
    ({ settings }) => settings
  );
  var translated_word = "";
  if (current_language && translation && translation[id]) {
    translated_word =
      translation[id][current_language.short_code] === undefined ||
      translation[id][current_language.short_code] === null
        ? translation[id]["EN"]
        : translation[id][current_language.short_code];
  }
  if (!translated_word) {
    return default_value;
  }
  return translated_word;
};

export default Translate;
