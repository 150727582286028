import React, { useEffect } from "react";
import { Button, Form, Input } from "antd";

import "antd/dist/antd.less";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { userSignIn, getUser } from "../appRedux/actions/Auth";
import IntlMessages from "../util/IntlMessages";
import InfoView from "../components/InfoView";
import { fetchTranslation } from "../appRedux/actions/Setting";

const FormItem = Form.Item;

const SignIn = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(({ auth }) => auth.token);
  const role = useSelector(({ auth }) => auth.role);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        dispatch(userSignIn(values));
      }
    });
  };

  useEffect(() => {
    if (token !== null && role === "admin") {
      dispatch(getUser());
      props.history.push("/");
      dispatch(fetchTranslation());
    }
  }, [token, props.history, role, dispatch]);

  const { getFieldDecorator } = props.form;
  var bg = require("../assets/images/bg.jpg");

  return (
    <div
      className="gx-app-login-wrap"
      style={{ backgroundImage: "url(" + bg + ")" }}
    >
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div
            className="gx-app-logo-content"
            style={{ backgroundColor: "#3FA1D9" }}
          >
            {/* <div className="gx-app-logo-content-bg">
              <img src="https://via.placeholder.com/272x395" alt="Neature" />
            </div> */}
            <div className="gx-app-logo-wid">
              <h1 style={{ fontSize: 28 }}>
                <b> FINBIT Administrator Login</b>
              </h1>

              {/* <p><IntlMessages id="app.userAuth.getAccount"/></p> */}
            </div>
            <div className="gx-app-logo">
              {/* <img alt="example" src={require("../assets/images/finbit.png")} style = {{ radius: '50%'}}/> */}
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              onSubmit={handleSubmit}
              className="gx-signin-form gx-form-row0"
            >
              <FormItem>
                {getFieldDecorator("username", {
                  // initialValue: "user@email.com",
                  rules: [
                    {
                      required: true,
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ],
                })(<Input placeholder="Email" />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator("password", {
                  // initialValue: "demo#123",
                  rules: [
                    { required: true, message: "Please input your Password!" },
                  ],
                })(<Input type="password" placeholder="Password" />)}
              </FormItem>
              {/* <FormItem>
                {getFieldDecorator('remember', {
                  valuePropName: 'checked',
                  initialValue: true,
                })(
                  <Checkbox><IntlMessages id="appModule.iAccept"/></Checkbox>
                )}
                <span className="gx-signup-form-forgot gx-link"><IntlMessages
                  id="appModule.termAndCondition"/></span>
              </FormItem> */}
              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>

                <Link to="/forgot-password" style={{ marginLeft: 120 }}>
                  <IntlMessages id="app.userAuth.forgotPassword" />
                </Link>
              </FormItem>
            </Form>
          </div>
          <InfoView />
        </div>
      </div>
    </div>
  );
};

const WrappedNormalLoginForm = Form.create()(SignIn);

export default WrappedNormalLoginForm;
