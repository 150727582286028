import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  USER_ROLE_SET,
} from "../../constants/ActionTypes";
import axios from "../../util/Api";
import axioss from "axios";
import { notification } from "antd";

import { createBrowserHistory } from "history";
import HandleError from "../hooks/useErrorHandler";

const history = createBrowserHistory();
// const router  = withRouter()

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const userSignUp = ({ email, password, name }) => {
  // console.log(email, password);
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("auth/register", {
        email: email,
        password: password,
        name: name,
      })
      .then(({ data }) => {
        // console.log("data:", data);
        if (data.result) {
          localStorage.setItem(
            "token",
            JSON.stringify(data.token.access_token)
          );
          axios.defaults.headers.common["authorization"] =
            "JWT " + data.token.access_token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });
          dispatch({ type: USER_DATA, payload: data.user });
        } else {
          console.log("payload: data.error", data.error);
          dispatch({ type: FETCH_ERROR, payload: "Network Error" });
        }
      })
      .catch(function (error) {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const userSignIn = ({ username, password }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("auth/token/", {
        username: username,
        password: password,
      })
      .then(({ data }) => {
        console.log("userSignIn: ", data);
        if (data) {
          localStorage.setItem("token", JSON.stringify(data.token));
          localStorage.setItem("role", JSON.stringify(data.role));

          axios.defaults.headers.common["authorization"] = "JWT " + data.token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_TOKEN_SET, payload: data.token });
          dispatch({ type: USER_ROLE_SET, payload: data.role });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response ? error.response.data.detail : error.message,
        });

        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};
export const resetPassword = ({ email }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axioss
      .post("https://api.finbit.co/api/password_reset/", {
        email: email,
      })
      .then(({ data }) => {
        console.log("resetPassword: ", data);
        if (data) {
          console.log("we have sent you an email");
          notification["success"]({
            message: "We have have sent you an email , please check your inbox",
            duration: 10,
          });
          dispatch({ type: FETCH_SUCCESS });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });

        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const confirmResetPassword = ({ password, token }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axioss
      .post("https://api.finbit.co/api/password_reset/confirm/", {
        token: token,
        password: password,
      })
      .then(({ data }) => {
        console.log("resetPassword: ", data);
        if (data) {
          history.push("#/data/dashboard/");
          notification["success"]({
            // message:  error.response ? "The session may be expired,please request again" : error.message,
            message:
              "You have reset your password succesfully, please login with your new password",
            duration: 5,
          });
          window.location.reload("false");

          dispatch({ type: FETCH_SUCCESS });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });

        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const confirmSetPassword = ({ password, token }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axioss
      .post("https://api.finbit.co/api/password_reset/confirm/", {
        token: token,
        password: password,
      })
      .then(({ data }) => {
        console.log("resetPassword: ", data);
        if (data) {
          history.push("#/data/dashboard/");
          notification["success"]({
            // message:  error.response ? "The session may be expired,please request again" : error.message,
            message: "You have set your password succesfully",
            duration: 5,
          });
          window.location.reload("false");

          dispatch({ type: FETCH_SUCCESS });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });

        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .get("/auth/me")
      .then(({ data }) => {
        console.log("AuthUser: ", data);
        if (data) {
          localStorage.setItem("authUser", JSON.stringify(data));
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_DATA, payload: data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const userSignOut = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("auth/logout")
      .then(({ data }) => {
        if (data.result) {
          localStorage.removeItem("token");
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SIGNOUT_USER_SUCCESS });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const SignOut = () => {
  localStorage.removeItem("token");
  return (dispatch) => {
    dispatch({ type: SIGNOUT_USER_SUCCESS });
  };
};
