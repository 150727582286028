import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  // GET_All_CONTACT_SUCCESS,
  GET_All_USER_SUCCESS,
  ON_ADD_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  GET_All_ADMIN_USER_SUCCESS,
  ON_ADD_ADMIN_USER_SUCCESS,
  DELETE_ADMIN_USER_SUCCESS,
  UPDATE_ADMIN_USER_SUCCESS,
  GET_All_LENDER_USER_SUCCESS,
  ON_ADD_LENDER_USER_SUCCESS,
  DELETE_LENDER_USER_SUCCESS,
  UPDATE_LENDER_USER_SUCCESS,
  GET_All_PORTAL_USER_SUCCESS,
  ON_ADD_PORTAL_USER_SUCCESS,
  DELETE_PORTAL_USER_SUCCESS,
  UPDATE_PORTAL_USER_SUCCESS,
  GET_All_RESEARCHER_USER_SUCCESS,
  ON_ADD_RESEARCHER_USER_SUCCESS,
  DELETE_RESEARCHER_USER_SUCCESS,
  UPDATE_RESEARCHER_USER_SUCCESS,
  GET_All_INSTITUTION_USER_SUCCESS,
  ON_ADD_INSTITUTION_USER_SUCCESS,
  DELETE_INSTITUTION_USER_SUCCESS,
  UPDATE_INSTITUTION_USER_SUCCESS,
  GET_All_CLIENT_USER_SUCCESS,
  ON_ADD_CLIENT_USER_SUCCESS,
  DELETE_CLIENT_USER_SUCCESS,
  UPDATE_CLIENT_USER_SUCCESS,
  GET_All_SURVEYOR_USER_SUCCESS,
  ON_ADD_SURVEYOR_USER_SUCCESS,
  DELETE_SURVEYOR_USER_SUCCESS,
  UPDATE_SURVEYOR_USER_SUCCESS,
  GET_All_EMPLOYER_USER_SUCCESS,
  ON_ADD_EMPLOYER_USER_SUCCESS,
  DELETE_EMPLOYER_USER_SUCCESS,
  UPDATE_EMPLOYER_USER_SUCCESS,
  GET_All_DEMOGRAPHIC_SUCCESS,
  ON_ADD_DEMOGRAPHIC_SUCCESS,
  DELETE_DEMOGRAPHIC_SUCCESS,
  UPDATE_DEMOGRAPHIC_SUCCESS,
  GET_All_EMPLOYEE_FORM_SUCCESS,
  ON_ADD_EMPLOYEE_FORM_SUCCESS,
  DELETE_EMPLOYEE_FORM_SUCCESS,
  UPDATE_EMPLOYEE_FORM_SUCCESS,
  GET_All_EMPLOYMENT_QUESTION_SUCCESS,
  DELETE_EMPLOYMENT_QUESTION_SUCCESS,
  GET_All_LOAN_QUESTION_SUCCESS,
  DELETE_LOAN_QUESTION_SUCCESS,
  GET_All_ASSET_TYPE_SUCCESS,
  ON_ADD_ASSET_TYPE_SUCCESS,
  DELETE_ASSET_TYPE_SUCCESS,
  UPDATE_ASSET_TYPE_SUCCESS,
  GET_All_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_SUCCESS,
  GET_All_AGRICULTURE_TYPE_SUCCESS,
  DELETE_AGRICULTURE_TYPE_SUCCESS,
  GET_All_BUSINESS_TYPE_SUCCESS,
  ON_ADD_BUSINESS_TYPE_SUCCESS,
  DELETE_BUSINESS_TYPE_SUCCESS,
  UPDATE_BUSINESS_TYPE_SUCCESS,
  GET_All_EMPLOYMENT_TYPE_SUCCESS,
  ON_ADD_EMPLOYMENT_TYPE_SUCCESS,
  DELETE_EMPLOYMENT_TYPE_SUCCESS,
  UPDATE_EMPLOYMENT_TYPE_SUCCESS,
  GET_All_OWNERSHIP_TYPE_SUCCESS,
  ON_ADD_OWNERSHIP_TYPE_SUCCESS,
  DELETE_OWNERSHIP_TYPE_SUCCESS,
  UPDATE_OWNERSHIP_TYPE_SUCCESS,
  GET_All_NON_WORK_INCOME_TYPE_SUCCESS,
  ON_ADD_NON_WORK_INCOME_TYPE_SUCCESS,
  DELETE_NON_WORK_INCOME_TYPE_SUCCESS,
  UPDATE_NON_WORK_INCOME_TYPE_SUCCESS,
  GET_All_EXPENSE_TYPE_SUCCESS,
  ON_ADD_EXPENSE_TYPE_SUCCESS,
  DELETE_EXPENSE_TYPE_SUCCESS,
  UPDATE_EXPENSE_TYPE_SUCCESS,
  GET_All_COST_TYPE_SUCCESS,
  ON_ADD_COST_TYPE_SUCCESS,
  DELETE_COST_TYPE_SUCCESS,
  UPDATE_COST_TYPE_SUCCESS,
  GET_All_SAVING_TOOLE_SUCCESS,
  ON_ADD_SAVING_TOOL_SUCCESS,
  DELETE_SAVING_TOOL_SUCCESS,
  UPDATE_SAVING_TOOL_SUCCESS,
  GET_All_LOAN_TOOLE_SUCCESS,
  ON_ADD_LOAN_TOOL_SUCCESS,
  DELETE_LOAN_TOOL_SUCCESS,
  UPDATE_LOAN_TOOL_SUCCESS,
  GET_All_LANGUAGE_SUCCESS,
  ON_ADD_LANGUAGE_SUCCESS,
  DELETE_LANGUAGE_SUCCESS,
  UPDATE_LANGUAGE_SUCCESS,
  GET_All_COMBINED_REPORT_DATA_SUCCESS,
  GET_All_USER_SPECIFIC_REPORT_DATA_SUCCESS,
  GET_All_SURVEY_DATA_SUCCESS,
  ON_ADD_SURVEY_DATA_SUCCESS,
  DELETE_SURVEY_DATA_SUCCESS,
  UPDATE_SURVEY_DATA_SUCCESS,
  GET_All_PROJECT_SUCCESS,
  ON_ADD_PROJECT_SUCCESS,
  DELETE_PROJECT_SUCCESS,
  UPDATE_PROJECT_SUCCESS,
  GET_All_RESEARCHERS_SUCCESS,
  GET_EXPORT_SUCCESS,
  GET_CHECK_EXPORT_SUCCESS,
  GET_ATTACHMENT_EXPORT_SUCCESS,
  GET_ATTACHMENT_CHECK_EXPORT_SUCCESS,
  GET_FILTERED_RESEARCHERS_SUCCESS,
  GET_INCOME_EXPORT_SUCCESS,
  GET_INCOME_CHECK_EXPORT_SUCCESS,
  GET_NOTIFICATION_EXPORT_SUCCESS,
  GET_NOTIFICATION_CHECK_EXPORT_SUCCESS,
  GET_SAVING_EXPORT_SUCCESS,
  GET_SAVING_CHECK_EXPORT_SUCCESS,
  GET_EXPENSE_EXPORT_SUCCESS,
  GET_EXPENSE_CHECK_EXPORT_SUCCESS,
  GET_EMPLOYEE_EXPORT_SUCCESS,
  GET_EMPLOYEE_CHECK_EXPORT_SUCCESS,
  GET_CREDIT_EXPORT_SUCCESS,
  GET_CREDIT_CHECK_EXPORT_SUCCESS,
  GET_STAT_SUCCESS,
  UPDATE_QUESTION_CATEGORY_SUCCESS,
  GET_APP_SETTINGS_SUCCESS,
  GET_DEFAULT_APP_SETTINGS_SUCCESS,
  ON_ADD_APP_SETTINGS_SUCCESS,
  UPDATE_APP_SETTINGS_SUCCESS,
  DELETE_APP_SETTINGS_SUCCESS,
  GET_PORTAL_SETTINGS_SUCCESS,
  GET_PERMISSIONS_SUCCESS,
  ON_ADD_PERMISSIONS_SUCCESS,
  UPDATE_PERMISSIONS_SUCCESS,
  DELETE_PERMISSIONS_SUCCESS,
  // DELETE_QUESTION_CATEGORY_SUCCESS,
  // ON_ADD_QUESTION_CAATEGORY,
  // ON_ADD_QUESTION_CATEGORY_SUCCESS,
  UPDATE_SURVEY_PART_CATEGORY_SUCCESS,
  DELETE_SURVEY_PART_CATEGORY_SUCCESS,
  ON_ADD_SURVEY_PART_CATEGORY_SUCCESS,
  DELETE_SURVEY_PART_QUESTION_SUCCESS,
  GET_GENERATED_REPORTS_SUCCESS,
  ON_UPDATE_SURVEY_PART_SUCCESS,
  ASSIGN_APP_SETTING,
  ASSIGN_DEFAULT_APP_SETTING,
  ASSIGN_PORTAL_SETTING,
  ASSIGN_SURVEYOR,
  ASSIGN_PERMISSION_GROUP,
  GET_FINANCE_EXPORT_SUCCESS,
  GET_FINANCE_CHECK_EXPORT_SUCCESS,
  GET_ASSETS_CHECK_EXPORT_SUCCESS,
  GET_ASSETS_EXPORT_SUCCESS,
  GET_LOAN_TAKEN_EXPORT_SUCCESS,
  GET_LOAN_GIVEN_CHECK_EXPORT_SUCCESS,
  GET_LOAN_TAKEN_CHECK_EXPORT_SUCCESS,
  GET_LOAN_GIVEN_EXPORT_SUCCESS,
  GET_GENERATED_INCOME_REPORTS_SUCCESS,
  GET_GENERATED_EXPENSE_REPORTS_SUCCESS,
  GET_GENERATED_CREDIT_REPORTS_SUCCESS,
  GET_GENERATED_LOAN_GIVEN_REPORTS_SUCCESS,
  GET_GENERATED_SAVING_REPORTS_SUCCESS,
  ON_ADD_EMPLOYEE_SURVEY_DATA_SUCCESS,
  DELETE_EMPLOYEE_SURVEY_DATA_SUCCESS,
  UPDATE_EMPLOYEE_SURVEY_DATA_SUCCESS,
  GET_All_EMPLOYEE_SURVEY_DATA_SUCCESS,
  GET_GENERATED_ASSET_REPORTS_SUCCESS,
  GET_GENERATED_EMPLOYEE_REPORTS_SUCCESS,
  GET_All_CLIENT_MANAGERS_SUCCESS,
  ON_ADD_CLIENT_MANAGERS_SUCCESS,
  DELETE_CLIENT_MANAGERS_SUCCESS,
  UPDATE_CLIENT_MANAGERS_SUCCESS,
  GET_DEMOGRAPHICS_ORDER_SUCCESS,
  ON_ADD_DEMOGRAPHIC_ORDER_SUCCESS,
  UPDATE_DEMOGRAPHIC_ORDER_SUCCESS,
  GET_EMPLOYEE_SURVEY_GENERATED_REPORTS_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  GET_DOWNLOAD_LINK_SUCCESS,
} from "../../constants/ActionTypes";

import { notification } from "antd";

import {
  ADMINISTRATOR,
  LENDER,
  PORTAL_USER,
  RESEARCHER,
  INSTITUTION,
  CLIENT,
  SURVEYOR,
  EMPLOYER,
  DEMOGRAPHIC,
  EMPLOYEE_FORM,
  EMPLOYMENT_Q,
  LOAN_Q,
  NOTIFICATION,
  ASSET_TYPE,
  AGRICULTURE_TYPE,
  BUSINESS_TYPE,
  EMPLOYMENT_TYPE,
  OWNERSHIP_TYPE,
  NON_WORK_INCOME_TYPE,
  EXPENSE_TYPE,
  COST_TYPE,
  SAVING_TOOL,
  LOAN_TOOL,
  LANGUAGE,
  COMBINED,
  USER_SPECIFIC,
  SURVEY,
  PROJECT,
  QUESTION_CATEGORY,
  SURVEY_PART_CATEGORY,
  SURVEY_PART_QUESTION,
  GENERATE_REPORT,
  APP_SETTING,
  PERMISSIONS,
  GENERATED_INCOME_REPORT,
  GENERATED_EXPENSE_REPORT,
  GENERATED_CREDIT_REPORT,
  GENERATED_LOAN_GIVEN_REPORT,
  GENERATED_SAVING_REPORT,
  DEFAULT_APP_SETTING,
  EMPLOYEE_SURVEY,
  CLIENT_MANAGER,
  GENERATED_ASSET_REPORT,
  GENERATED_EMPLOYEE_REPORT,
  DEMOGRAPHIC_ORDER,
  EMPLOYEE_SURVEY_GENERATED_REPORT,
  PORTAL_SETTING,
} from "../../constants/DataTypes";

import axios from "../../util/Api";
import HandleError from "../hooks/useErrorHandler";

// import {createBrowserHistory} from "history";

// const history = createBrowserHistory();

export const fetchData = (
  endpoint,
  dataType = "",
  params = null,
  callback = null
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .get(`/${endpoint}/`, { params: params })
      .then(({ data }) => {
        if (data) {
          switch (dataType) {
            case ADMINISTRATOR:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_ADMIN_USER_SUCCESS,
                payload: data,
              });
              break;
            case LENDER:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_LENDER_USER_SUCCESS,
                payload: data,
              });
              break;
            case PORTAL_USER:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_PORTAL_USER_SUCCESS,
                payload: data,
              });
              break;
            case RESEARCHER:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_RESEARCHER_USER_SUCCESS,
                payload: data,
              });
              if (callback !== null) {
                callback();
              }
              break;
            case INSTITUTION:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_INSTITUTION_USER_SUCCESS,
                payload: data,
              });
              break;
            case CLIENT:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_CLIENT_USER_SUCCESS,
                payload: data,
              });
              break;
            case SURVEYOR:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_SURVEYOR_USER_SUCCESS,
                payload: data,
              });
              break;
            case EMPLOYER:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_EMPLOYER_USER_SUCCESS,
                payload: data,
              });
              break;
            case APP_SETTING:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_APP_SETTINGS_SUCCESS,
                payload: data,
              });
              break;
            case DEFAULT_APP_SETTING:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_DEFAULT_APP_SETTINGS_SUCCESS,
                payload: data,
              });
              break;
            case PORTAL_SETTING:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_PORTAL_SETTINGS_SUCCESS,
                payload: data,
              });
              break;
            case PERMISSIONS:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_PERMISSIONS_SUCCESS,
                payload: data,
              });
              break;
            case DEMOGRAPHIC:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_DEMOGRAPHIC_SUCCESS,
                payload: data,
              });
              break;
            case EMPLOYEE_FORM:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_EMPLOYEE_FORM_SUCCESS,
                payload: data,
              });
              break;
            case EMPLOYMENT_Q:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_EMPLOYMENT_QUESTION_SUCCESS,
                payload: data,
              });
              break;
            case LOAN_Q:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_LOAN_QUESTION_SUCCESS,
                payload: data,
              });
              break;
            case NOTIFICATION:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_NOTIFICATION_SUCCESS,
                payload: data,
              });
              break;
            case ASSET_TYPE:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_ASSET_TYPE_SUCCESS,
                payload: data,
              });
              break;
            case AGRICULTURE_TYPE:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_AGRICULTURE_TYPE_SUCCESS,
                payload: data,
              });
              break;
            case BUSINESS_TYPE:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_BUSINESS_TYPE_SUCCESS,
                payload: data,
              });
              break;
            case EMPLOYMENT_TYPE:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_EMPLOYMENT_TYPE_SUCCESS,
                payload: data,
              });
              break;
            case OWNERSHIP_TYPE:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_OWNERSHIP_TYPE_SUCCESS,
                payload: data,
              });
              break;
            case NON_WORK_INCOME_TYPE:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_NON_WORK_INCOME_TYPE_SUCCESS,
                payload: data,
              });
              break;
            case EXPENSE_TYPE:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_EXPENSE_TYPE_SUCCESS,
                payload: data,
              });
              break;
            case COST_TYPE:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_COST_TYPE_SUCCESS,
                payload: data,
              });
              break;
            case SAVING_TOOL:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_SAVING_TOOLE_SUCCESS,
                payload: data,
              });
              break;
            case LOAN_TOOL:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_LOAN_TOOLE_SUCCESS,
                payload: data,
              });
              break;
            case LANGUAGE:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_LANGUAGE_SUCCESS,
                payload: data,
              });
              break;
            case SURVEY:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_SURVEY_DATA_SUCCESS,
                payload: data,
              });
              break;
            case EMPLOYEE_SURVEY:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_EMPLOYEE_SURVEY_DATA_SUCCESS,
                payload: data,
              });
              break;
            case PROJECT:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_PROJECT_SUCCESS,
                payload: data,
              });
              break;
            case DEMOGRAPHIC_ORDER:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_DEMOGRAPHICS_ORDER_SUCCESS,
                payload: data,
              });
              break;
            default:
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: GET_All_USER_SUCCESS,
                payload: data,
              });
          }
        } else {
          dispatch({ type: FETCH_ERROR, payload: "Network Error" });
        }
      })
      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          if (!dataType === DEMOGRAPHIC_ORDER) {
            notification["error"]({
              message: errorMessage,
              duration: 2,
            });
          }
        });
      });
  };
};

export const fetchStatData = (endpoint) => {
  return (dispatch) => {
    // dispatch({type: FETCH_START});
    axios
      .get(`/${endpoint}/`)
      .then((res) => {
        const data = res.data;
        dispatch({
          type: GET_STAT_SUCCESS,
          payload: data,
        });
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const fetchData2 = (endpoint, dataType = "") => {
  return (dispatch) => {
    // dispatch({type: FETCH_START});
    axios
      .get(`/${endpoint}`)
      .then((res) => {
        const data = res.data;
        switch (dataType) {
          case GENERATE_REPORT:
            dispatch({
              type: GET_GENERATED_REPORTS_SUCCESS,
              payload: data,
            });
            break;
          case EMPLOYEE_SURVEY_GENERATED_REPORT:
            dispatch({
              type: GET_EMPLOYEE_SURVEY_GENERATED_REPORTS_SUCCESS,
              payload: data,
            });
            break;
          case GENERATED_INCOME_REPORT:
            dispatch({
              type: GET_GENERATED_INCOME_REPORTS_SUCCESS,
              payload: data,
            });
            break;
          case GENERATED_EXPENSE_REPORT:
            dispatch({
              type: GET_GENERATED_EXPENSE_REPORTS_SUCCESS,
              payload: data,
            });
            break;
          case GENERATED_CREDIT_REPORT:
            dispatch({
              type: GET_GENERATED_CREDIT_REPORTS_SUCCESS,
              payload: data,
            });
            break;
          case GENERATED_LOAN_GIVEN_REPORT:
            dispatch({
              type: GET_GENERATED_LOAN_GIVEN_REPORTS_SUCCESS,
              payload: data,
            });
            break;

          case GENERATED_SAVING_REPORT:
            dispatch({
              type: GET_GENERATED_SAVING_REPORTS_SUCCESS,
              payload: data,
            });
            break;
          case GENERATED_ASSET_REPORT:
            dispatch({
              type: GET_GENERATED_ASSET_REPORTS_SUCCESS,
              payload: data,
            });
            break;
          case GENERATED_EMPLOYEE_REPORT:
            dispatch({
              type: GET_GENERATED_EMPLOYEE_REPORTS_SUCCESS,
              payload: data,
            });
            break;
          default:
            dispatch({
              type: GET_All_USER_SUCCESS,
              payload: data,
            });
          // notification['success']({
          //   message: 'Data fetched successfully',
          //   duration: 2
          // });
        }
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onPatchData = (endpoint, data) => {
  return (dispatch) => {
    return axios
      .patch(`/${endpoint}`, data)
      .then(({ data }) => {
        dispatch({ type: UPDATE_RESEARCHER_USER_SUCCESS, payload: data });
        notification["success"]({
          message: "Researcher updated successfully",
          duration: 2,
        });
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onPublish = (id, data) => {
  return (dispatch) => {
    return axios
      .patch(`/survey/${id}/`, data)
      .then(({ data }) => {
        dispatch({ type: UPDATE_SURVEY_DATA_SUCCESS, payload: data });
        notification["success"]({
          message: "Survey Publishment Data Changed",
          duration: 2,
        });
      })
      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onAddData = (data, endpoint, dataType = "") => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}/`, data)
      .then(({ data }) => {
        switch (dataType) {
          case ADMINISTRATOR:
            dispatch({
              type: ON_ADD_ADMIN_USER_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Administrator added successfully",
              duration: 2,
            });
            break;
          case LENDER:
            dispatch({
              type: ON_ADD_LENDER_USER_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Lender added successfully",
              duration: 2,
            });
            break;
          case PORTAL_USER:
            dispatch({
              type: ON_ADD_PORTAL_USER_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Portal user added successfully",
              duration: 2,
            });
            break;
          case RESEARCHER:
            dispatch({
              type: ON_ADD_RESEARCHER_USER_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Researcher added successfully",
              duration: 2,
            });
            break;
          case INSTITUTION:
            dispatch({
              type: ON_ADD_INSTITUTION_USER_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Institution added successfully",
              duration: 2,
            });
            break;
          case CLIENT:
            dispatch({
              type: ON_ADD_CLIENT_USER_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Client added successfully",
              duration: 2,
            });
            break;
          case SURVEYOR:
            dispatch({
              type: ON_ADD_SURVEYOR_USER_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Surveyor added successfully",
              duration: 2,
            });
            break;
          case EMPLOYER:
            dispatch({
              type: ON_ADD_EMPLOYER_USER_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Employer added successfully",
              duration: 2,
            });
            break;
          case APP_SETTING:
            dispatch({
              type: ON_ADD_APP_SETTINGS_SUCCESS,
              payload: null,
            });
            notification["success"]({
              message: "App setting added successfully",
              duration: 2,
            });
            break;
          case DEMOGRAPHIC:
            dispatch({
              type: ON_ADD_DEMOGRAPHIC_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Demographic added successfully",
              duration: 2,
            });
            break;
          case EMPLOYEE_FORM:
            dispatch({
              type: ON_ADD_EMPLOYEE_FORM_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Employee attribute added successfully",
              duration: 2,
            });
            break;
          case ASSET_TYPE:
            dispatch({
              type: ON_ADD_ASSET_TYPE_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Asset type added successfully",
              duration: 2,
            });
            break;
          case BUSINESS_TYPE:
            dispatch({
              type: ON_ADD_BUSINESS_TYPE_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Business type added successfully",
              duration: 2,
            });
            break;
          case EMPLOYMENT_TYPE:
            dispatch({
              type: ON_ADD_EMPLOYMENT_TYPE_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Employment type added successfully",
              duration: 2,
            });
            break;
          case OWNERSHIP_TYPE:
            dispatch({
              type: ON_ADD_OWNERSHIP_TYPE_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Ownership type added successfully",
              duration: 2,
            });
            break;
          case NON_WORK_INCOME_TYPE:
            dispatch({
              type: ON_ADD_NON_WORK_INCOME_TYPE_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Non-work income type added successfully",
              duration: 2,
            });
            break;
          case EXPENSE_TYPE:
            dispatch({
              type: ON_ADD_EXPENSE_TYPE_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Expense type added successfully",
              duration: 2,
            });
            break;
          case COST_TYPE:
            dispatch({
              type: ON_ADD_COST_TYPE_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Cost type added successfully",
              duration: 2,
            });
            break;
          case SAVING_TOOL:
            dispatch({
              type: ON_ADD_SAVING_TOOL_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Saving tool added successfully",
              duration: 2,
            });
            break;
          case LOAN_TOOL:
            dispatch({
              type: ON_ADD_LOAN_TOOL_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Loan tool added successfully",
              duration: 2,
            });
            break;
          case LANGUAGE:
            dispatch({
              type: ON_ADD_LANGUAGE_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Language added successfully",
              duration: 2,
            });
            break;
          case SURVEY:
            dispatch({
              type: ON_ADD_SURVEY_DATA_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Survey added successfully",
              duration: 2,
            });
            break;
          case EMPLOYEE_SURVEY:
            dispatch({
              type: ON_ADD_EMPLOYEE_SURVEY_DATA_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Employee Survey added successfully",
              duration: 2,
            });
            break;
          case PROJECT:
            dispatch({
              type: ON_ADD_PROJECT_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Project added successfully",
              duration: 2,
            });
            break;
          case DEMOGRAPHIC_ORDER:
            dispatch({
              type: ON_ADD_DEMOGRAPHIC_ORDER_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Demographic Order added successfully",
              duration: 2,
            });
            break;
          case SURVEY_PART_CATEGORY:
            dispatch({
              type: ON_ADD_SURVEY_PART_CATEGORY_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Survey part category added successfully",
              duration: 2,
            });
            break;
          default:
            dispatch({
              type: ON_ADD_USER_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Data added successfully",
              duration: 2,
            });
        }
      })
      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onPostData = (endpoint, data, dataType = "") => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}/`, data)
      .then(({ data }) => {
        switch (dataType) {
          case COMBINED:
            dispatch({
              type: GET_All_COMBINED_REPORT_DATA_SUCCESS,
              payload: data,
            });

            break;
          case USER_SPECIFIC:
            dispatch({
              type: GET_All_USER_SPECIFIC_REPORT_DATA_SUCCESS,
              payload: data,
            });

            break;
          default:
            dispatch({
              type: GET_All_USER_SUCCESS,
              payload: data,
            });

            break;
        }
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onAssignAppSettingData = (endpoint, data) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}/`, data)
      .then(({ data }) => {
        dispatch({
          type: ASSIGN_APP_SETTING,
          payload: data,
        });
        notification["success"]({
          message: "App Setting assigned successfully!",
          duration: 2,
        });
      })
      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onAssignDefaultAppSettingData = (endpoint, data) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}/`, data)
      .then(({ data }) => {
        dispatch({
          type: ASSIGN_DEFAULT_APP_SETTING,
          payload: data,
        });
        notification["success"]({
          message: "Default App Setting assigned successfully!",
          duration: 2,
        });
      })
      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const getDownloadLink = (id, type) => {
  return (dispatch) => {
    // dispatch({type: FETCH_START});
    axios
      .get(`/generate_download_link?type=${type}&id=${id}`)
      .then((res) => {
        const data = res.data;
        dispatch({
          type: GET_DOWNLOAD_LINK_SUCCESS,
          payload: data,
        });
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onAssignPortalSettingData = (endpoint, data) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}/`, data)
      .then(({ data }) => {
        dispatch({
          type: ASSIGN_PORTAL_SETTING,
          payload: data,
        });
        notification["success"]({
          message: "Portal Setting assigned successfully!",
          duration: 2,
        });
      })
      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onAssignSurveyorData = (endpoint, data) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}/`, data)
      .then(({ data }) => {
        dispatch({
          type: ASSIGN_SURVEYOR,
          payload: data,
        });
        notification["success"]({
          message: "Surveyor assigned successfully!",
          duration: 2,
        });
      })
      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onAssignPermissionGroupsData = (endpoint, data) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}/`, data)
      .then(({ data }) => {
        dispatch({
          type: ASSIGN_PERMISSION_GROUP,
          payload: data,
        });
        notification["success"]({
          message: "Permission Group assigned successfully!",
          duration: 2,
        });
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onfilterResearchers = (endpoint, data) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}/`, data)
      .then(({ data }) => {
        dispatch({
          type: GET_All_RESEARCHERS_SUCCESS,
          payload: data,
        });
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onDeleteData2 = (id, endpoint, dataType = "") => {
  return (dispatch) => {
    return axios
      .delete(`/${endpoint}`)
      .then(() => {
        switch (dataType) {
          case SURVEY_PART_CATEGORY:
            dispatch({
              type: DELETE_SURVEY_PART_CATEGORY_SUCCESS,
              payload: id,
            });
            notification["success"]({
              message: "Survey part category deleted successfully",
              duration: 2,
            });
            break;
          default:
            break;
        }
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onDeleteData = (id, endpoint, dataType = "") => {
  return (dispatch) => {
    return axios
      .delete(`/${endpoint}/${id}/`)
      .then((response) => {
        switch (dataType) {
          case ADMINISTRATOR:
            dispatch({ type: DELETE_ADMIN_USER_SUCCESS, payload: id });
            notification["success"]({
              message: "Administrator deleted successfully",
              duration: 2,
            });
            break;
          case LENDER:
            dispatch({ type: DELETE_LENDER_USER_SUCCESS, payload: id });
            notification["success"]({
              message: "Lender deleted successfully",
              duration: 2,
            });
            break;
          case PORTAL_USER:
            dispatch({ type: DELETE_PORTAL_USER_SUCCESS, payload: id });
            notification["success"]({
              message: "Portal user deleted successfully",
              duration: 2,
            });
            break;
          case RESEARCHER:
            dispatch({ type: DELETE_RESEARCHER_USER_SUCCESS, payload: id });
            notification["success"]({
              message: "Researcher deleted successfully",
              duration: 2,
            });
            break;
          case INSTITUTION:
            dispatch({ type: DELETE_INSTITUTION_USER_SUCCESS, payload: id });
            notification["success"]({
              message: "Institution deleted successfully",
              duration: 2,
            });
            break;
          case CLIENT:
            dispatch({ type: DELETE_CLIENT_USER_SUCCESS, payload: id });
            notification["success"]({
              message: "Client deleted successfully",
              duration: 2,
            });
            break;
          case SURVEYOR:
            dispatch({ type: DELETE_SURVEYOR_USER_SUCCESS, payload: id });
            notification["success"]({
              message: "Surveyor deleted successfully",
              duration: 2,
            });
            break;
          case EMPLOYER:
            dispatch({ type: DELETE_EMPLOYER_USER_SUCCESS, payload: id });
            notification["success"]({
              message: "Employer deleted successfully",
              duration: 2,
            });
            break;
          case EMPLOYER:
            dispatch({ type: DELETE_APP_SETTINGS_SUCCESS, payload: id });
            notification["success"]({
              message: "App settings deleted successfully",
              duration: 2,
            });
            break;
          case DEMOGRAPHIC:
            dispatch({ type: DELETE_DEMOGRAPHIC_SUCCESS, payload: id });
            notification["success"]({
              message: "Demographic deleted successfully",
              duration: 2,
            });
            break;
          case EMPLOYEE_FORM:
            dispatch({ type: DELETE_EMPLOYEE_FORM_SUCCESS, payload: id });
            notification["success"]({
              message: "Employee attribute deleted successfully",
              duration: 2,
            });
            break;
          case EMPLOYMENT_Q:
            dispatch({ type: DELETE_EMPLOYMENT_QUESTION_SUCCESS, payload: id });
            notification["success"]({
              message: "Employment question deleted successfully",
              duration: 2,
            });
            break;
          case LOAN_Q:
            dispatch({ type: DELETE_LOAN_QUESTION_SUCCESS, payload: id });
            notification["success"]({
              message: "Loan question deleted successfully",
              duration: 2,
            });
            break;
          case NOTIFICATION:
            dispatch({ type: DELETE_NOTIFICATION_SUCCESS, payload: id });
            notification["success"]({
              message: "Notification deleted successfully",
              duration: 2,
            });
            break;
          case ASSET_TYPE:
            dispatch({ type: DELETE_ASSET_TYPE_SUCCESS, payload: id });
            notification["success"]({
              message: "Asset type deleted successfully",
              duration: 2,
            });
            break;
          case AGRICULTURE_TYPE:
            dispatch({ type: DELETE_AGRICULTURE_TYPE_SUCCESS, payload: id });
            notification["success"]({
              message: "Agriculture type deleted successfully",
              duration: 2,
            });
            break;
          case BUSINESS_TYPE:
            dispatch({ type: DELETE_BUSINESS_TYPE_SUCCESS, payload: id });
            notification["success"]({
              message: "Business type deleted successfully",
              duration: 2,
            });
            break;
          case EMPLOYMENT_TYPE:
            dispatch({ type: DELETE_EMPLOYMENT_TYPE_SUCCESS, payload: id });
            notification["success"]({
              message: "Employment type deleted successfully",
              duration: 2,
            });
            break;
          case OWNERSHIP_TYPE:
            dispatch({ type: DELETE_OWNERSHIP_TYPE_SUCCESS, payload: id });
            notification["success"]({
              message: "Ownership type deleted successfully",
              duration: 2,
            });
            break;
          case NON_WORK_INCOME_TYPE:
            dispatch({
              type: DELETE_NON_WORK_INCOME_TYPE_SUCCESS,
              payload: id,
            });
            notification["success"]({
              message: "Non-work income type deleted successfully",
              duration: 2,
            });
            break;
          case EXPENSE_TYPE:
            dispatch({ type: DELETE_EXPENSE_TYPE_SUCCESS, payload: id });
            notification["success"]({
              message: "Expense type deleted successfully",
              duration: 2,
            });
            break;
          case COST_TYPE:
            dispatch({ type: DELETE_COST_TYPE_SUCCESS, payload: id });
            notification["success"]({
              message: "Cost type deleted successfully",
              duration: 2,
            });
            break;
          case SAVING_TOOL:
            dispatch({ type: DELETE_SAVING_TOOL_SUCCESS, payload: id });
            notification["success"]({
              message: "Saving tool deleted successfully",
              duration: 2,
            });
            break;
          case LOAN_TOOL:
            dispatch({ type: DELETE_LOAN_TOOL_SUCCESS, payload: id });
            notification["success"]({
              message: "Loan tool deleted successfully",
              duration: 2,
            });
            break;
          case LANGUAGE:
            dispatch({ type: DELETE_LANGUAGE_SUCCESS, payload: id });
            notification["success"]({
              message: "Language deleted successfully",
              duration: 2,
            });
            break;
          case SURVEY:
            dispatch({ type: DELETE_SURVEY_DATA_SUCCESS, payload: id });
            notification["success"]({
              message: "Survey deleted successfully",
              duration: 2,
            });
            break;
          case EMPLOYEE_SURVEY:
            dispatch({
              type: DELETE_EMPLOYEE_SURVEY_DATA_SUCCESS,
              payload: id,
            });
            notification["success"]({
              message: "Employee Survey deleted successfully",
              duration: 2,
            });
            break;
          case PROJECT:
            dispatch({ type: DELETE_PROJECT_SUCCESS, payload: id });
            notification["success"]({
              message: "Project deleted successfully",
              duration: 2,
            });
            break;
          case SURVEY_PART_CATEGORY:
            dispatch({
              type: DELETE_SURVEY_PART_CATEGORY_SUCCESS,
              payload: id,
            });
            notification["success"]({
              message: "Survey part category deleted successfully",
              duration: 2,
            });
            break;
          case SURVEY_PART_QUESTION:
            dispatch({
              type: DELETE_SURVEY_PART_QUESTION_SUCCESS,
              payload: id,
            });
            notification["success"]({
              message: "Survey part question deleted successfully",
              duration: 2,
            });
            break;
          default:
            dispatch({ type: DELETE_USER_SUCCESS, payload: id });
            notification["success"]({
              message: "Data deleted successfully",
              duration: 2,
            });
        }
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onUpdateData2 = (id, user, endpoint, dataType = "") => {
  return (dispatch) => {
    return axios
      .patch(`/${endpoint}`, user)
      .then(({ data }) => {
        switch (dataType) {
          case SURVEY_PART_CATEGORY:
            dispatch({
              type: UPDATE_SURVEY_PART_CATEGORY_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Survey part category updated successfully",
              duration: 2,
            });
            break;
          default:
            break;
        }
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};
export const onUpdateSurveyPartsData = (endpoint, name) => {
  return (dispatch) => {
    return axios
      .patch(`/${endpoint}`, name)
      .then(({ data }) => {
        dispatch({ type: ON_UPDATE_SURVEY_PART_SUCCESS, payload: data });
        notification["success"]({
          message: "Survey Part updated successfully",
          duration: 2,
        });
      })
      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onUpdateData = (id, user, endpoint, dataType = "") => {
  return (dispatch) => {
    return axios
      .patch(`/${endpoint}/${id}/`, user)
      .then(({ data }) => {
        switch (dataType) {
          case ADMINISTRATOR:
            dispatch({ type: UPDATE_ADMIN_USER_SUCCESS, payload: data });
            notification["success"]({
              message: "Administrator updated successfully",
              duration: 2,
            });
            break;
          case LENDER:
            dispatch({ type: UPDATE_LENDER_USER_SUCCESS, payload: data });
            notification["success"]({
              message: "Lender updated successfully",
              duration: 2,
            });
            break;
          case PORTAL_USER:
            dispatch({ type: UPDATE_PORTAL_USER_SUCCESS, payload: data });
            notification["success"]({
              message: "Portal user updated successfully",
              duration: 2,
            });
            break;
          case RESEARCHER:
            dispatch({ type: UPDATE_RESEARCHER_USER_SUCCESS, payload: data });
            notification["success"]({
              message: "Researcher updated successfully",
              duration: 2,
            });
            break;
          case INSTITUTION:
            dispatch({ type: UPDATE_INSTITUTION_USER_SUCCESS, payload: data });
            notification["success"]({
              message: "Institution updated successfully",
              duration: 2,
            });
            break;
          case CLIENT:
            dispatch({ type: UPDATE_CLIENT_USER_SUCCESS, payload: data });
            notification["success"]({
              message: "Client updated successfully",
              duration: 2,
            });
            break;
          case SURVEYOR:
            dispatch({ type: UPDATE_SURVEYOR_USER_SUCCESS, payload: data });
            notification["success"]({
              message: "Surveyor updated successfully",
              duration: 2,
            });
            break;
          case EMPLOYER:
            dispatch({ type: UPDATE_EMPLOYER_USER_SUCCESS, payload: data });
            notification["success"]({
              message: "Employer updated successfully",
              duration: 2,
            });
            break;
          case EMPLOYER:
            dispatch({ type: UPDATE_APP_SETTINGS_SUCCESS, payload: data });
            notification["success"]({
              message: "App setting updated successfully",
              duration: 2,
            });
            break;
          case DEMOGRAPHIC:
            dispatch({ type: UPDATE_DEMOGRAPHIC_SUCCESS, payload: data });
            notification["success"]({
              message: "Demographic updated successfully",
              duration: 2,
            });
            break;
          case EMPLOYEE_FORM:
            dispatch({ type: UPDATE_EMPLOYEE_FORM_SUCCESS, payload: data });
            notification["success"]({
              message: "Employee attribute updated successfully",
              duration: 2,
            });
            break;
          case ASSET_TYPE:
            dispatch({ type: UPDATE_ASSET_TYPE_SUCCESS, payload: data });
            notification["success"]({
              message: "Asset type updated successfully",
              duration: 2,
            });
            break;
          case BUSINESS_TYPE:
            dispatch({ type: UPDATE_BUSINESS_TYPE_SUCCESS, payload: data });
            notification["success"]({
              message: "Business type updated successfully",
              duration: 2,
            });
            break;
          case EMPLOYMENT_TYPE:
            dispatch({ type: UPDATE_EMPLOYMENT_TYPE_SUCCESS, payload: data });
            notification["success"]({
              message: "Employment type updated successfully",
              duration: 2,
            });
            break;
          case OWNERSHIP_TYPE:
            dispatch({ type: UPDATE_OWNERSHIP_TYPE_SUCCESS, payload: data });
            notification["success"]({
              message: "Ownership type updated successfully",
              duration: 2,
            });
            break;
          case NON_WORK_INCOME_TYPE:
            dispatch({
              type: UPDATE_NON_WORK_INCOME_TYPE_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Non-work income type updated successfully",
              duration: 2,
            });
            break;
          case EXPENSE_TYPE:
            dispatch({ type: UPDATE_EXPENSE_TYPE_SUCCESS, payload: data });
            notification["success"]({
              message: "Expense type updated successfully",
              duration: 2,
            });
            break;
          case COST_TYPE:
            dispatch({ type: UPDATE_COST_TYPE_SUCCESS, payload: data });
            notification["success"]({
              message: "Cost type updated successfully",
              duration: 2,
            });
            break;
          case SAVING_TOOL:
            dispatch({ type: UPDATE_SAVING_TOOL_SUCCESS, payload: data });
            notification["success"]({
              message: "Saving tool updated successfully",
              duration: 2,
            });
            break;
          case LOAN_TOOL:
            dispatch({ type: UPDATE_LOAN_TOOL_SUCCESS, payload: data });
            notification["success"]({
              message: "Loan tool updated successfully",
              duration: 2,
            });
            break;
          case LANGUAGE:
            dispatch({ type: UPDATE_LANGUAGE_SUCCESS, payload: data });
            notification["success"]({
              message: "Language updated successfully",
              duration: 2,
            });
            break;
          case SURVEY:
            dispatch({ type: UPDATE_SURVEY_DATA_SUCCESS, payload: data });
            notification["success"]({
              message: "Survey updated successfully",
              duration: 2,
            });
            break;
          case EMPLOYEE_SURVEY:
            dispatch({
              type: UPDATE_EMPLOYEE_SURVEY_DATA_SUCCESS,
              payload: data,
            });
            notification["success"]({
              message: "Employee Survey updated successfully",
              duration: 2,
            });
            break;
          case SURVEY:
            dispatch({ type: UPDATE_PROJECT_SUCCESS, payload: data });
            notification["success"]({
              message: "Project updated successfully",
              duration: 2,
            });
            break;
          case QUESTION_CATEGORY:
            dispatch({ type: UPDATE_QUESTION_CATEGORY_SUCCESS, payload: data });
            notification["success"]({
              message: "Question category updated successfully",
              duration: 2,
            });
            break;
          case DEMOGRAPHIC_ORDER:
            dispatch({ type: UPDATE_DEMOGRAPHIC_ORDER_SUCCESS, payload: data });
            notification["success"]({
              message: "Demographics order updated successfully",
              duration: 2,
            });
            break;
          default:
            dispatch({ type: UPDATE_USER_SUCCESS, payload: data });
            notification["success"]({
              message: "Data updated successfully",
              duration: 2,
            });
            break;
        }
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onExportData = (endpoint, data) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}/`, data)
      .then(({ data }) => {
        dispatch({
          type: GET_EXPORT_SUCCESS,
          payload: data,
        });
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onExportAttachment = (endpoint, data) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}/`, data)
      .then(({ data }) => {
        dispatch({
          type: GET_ATTACHMENT_EXPORT_SUCCESS,
          payload: data,
        });
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onCheckReport = (endpoint, data) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: GET_CHECK_EXPORT_SUCCESS,
          payload: data,
        });
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};
export const onCheckAttachment = (endpoint, data) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: GET_ATTACHMENT_CHECK_EXPORT_SUCCESS,
          payload: data,
        });
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onIncomeExportData = (endpoint, data, isSchedule = false) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}/`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: GET_INCOME_EXPORT_SUCCESS,
          payload: data,
        });

        if (isSchedule) {
          notification["success"]({
            message: "Export has scheduled successfully.",
            duration: 2,
          });
        }
      })

      .catch((error) => {
        //console.log("e", error)
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onIncomeCheckReport = (endpoint, data) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: GET_INCOME_CHECK_EXPORT_SUCCESS,
          payload: data,
        });
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onFinanceExportData = (endpoint, data, isSchedule = false) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}/`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: GET_FINANCE_EXPORT_SUCCESS,
          payload: data,
        });

        if (isSchedule) {
          notification["success"]({
            message: "Export has scheduled successfully.",
            duration: 2,
          });
        }
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onFinanceCheckReport = (endpoint, data) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: GET_FINANCE_CHECK_EXPORT_SUCCESS,
          payload: data,
        });
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onSavingExportData = (endpoint, data, isSchedule = false) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}/`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: GET_SAVING_EXPORT_SUCCESS,
          payload: data,
        });

        if (isSchedule) {
          notification["success"]({
            message: "Export has scheduled successfully.",
            duration: 2,
          });
        }
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onSavingCheckReport = (endpoint, data) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: GET_SAVING_CHECK_EXPORT_SUCCESS,
          payload: data,
        });
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onNotificationExportData = (
  endpoint,
  data,
  isSchedule = false
) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}/`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: GET_NOTIFICATION_EXPORT_SUCCESS,
          payload: data,
        });

        if (isSchedule) {
          notification["success"]({
            message: "Export has scheduled successfully.",
            duration: 2,
          });
        }
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onNotificationCheckReport = (endpoint, data) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: GET_NOTIFICATION_CHECK_EXPORT_SUCCESS,
          payload: data,
        });
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onEmployeeExportData = (endpoint, data, isSchedule = false) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}/`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: GET_EMPLOYEE_EXPORT_SUCCESS,
          payload: data,
        });

        if (isSchedule) {
          notification["success"]({
            message: "Export has scheduled successfully.",
            duration: 2,
          });
        }
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onEmployeeCheckReport = (endpoint, data) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: GET_EMPLOYEE_CHECK_EXPORT_SUCCESS,
          payload: data,
        });
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onExpenseExportData = (endpoint, data, isSchedule = false) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}/`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: GET_EXPENSE_EXPORT_SUCCESS,
          payload: data,
        });

        if (isSchedule) {
          notification["success"]({
            message: "Export has scheduled successfully.",
            duration: 2,
          });
        }
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onExpenseCheckReport = (endpoint, data) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: GET_EXPENSE_CHECK_EXPORT_SUCCESS,
          payload: data,
        });
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onCreditExportData = (endpoint, data, isSchedule = false) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}/`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: GET_CREDIT_EXPORT_SUCCESS,
          payload: data,
        });

        if (isSchedule) {
          notification["success"]({
            message: "Export has scheduled successfully.",
            duration: 2,
          });
        }
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onCreditCheckReport = (endpoint, data) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: GET_CREDIT_CHECK_EXPORT_SUCCESS,
          payload: data,
        });
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onAssetsExportData = (endpoint, data, isSchedule = false) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}/`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: GET_ASSETS_EXPORT_SUCCESS,
          payload: data,
        });

        if (isSchedule) {
          notification["success"]({
            message: "Export has scheduled successfully.",
            duration: 2,
          });
        }
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onAssetsCheckReport = (endpoint, data) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: GET_ASSETS_CHECK_EXPORT_SUCCESS,
          payload: data,
        });
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onLoanTakenExportData = (endpoint, data, isSchedule = false) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}/`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: GET_LOAN_TAKEN_EXPORT_SUCCESS,
          payload: data,
        });

        if (isSchedule) {
          notification["success"]({
            message: "Export has scheduled successfully.",
            duration: 2,
          });
        }
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onLoanTakenCheckReport = (endpoint, data) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: GET_LOAN_TAKEN_CHECK_EXPORT_SUCCESS,
          payload: data,
        });
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onLoanGivenExportData = (endpoint, data, isSchedule = false) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}/`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: GET_LOAN_GIVEN_EXPORT_SUCCESS,
          payload: data,
        });

        if (isSchedule) {
          notification["success"]({
            message: "Export has scheduled successfully.",
            duration: 2,
          });
        }
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onLoanGivenCheckReport = (endpoint, data) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: GET_LOAN_GIVEN_CHECK_EXPORT_SUCCESS,
          payload: data,
        });
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onFetchFilteredResearchers = (endpoint, data) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}/`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: GET_FILTERED_RESEARCHERS_SUCCESS,
          payload: data,
        });
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

export const onPasswordReset = (endpoint, data) => {
  return (dispatch) => {
    return axios
      .post(`/${endpoint}`, data)
      .then((response) => {
        let data = response.data;
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          payload: data,
        });
        notification["success"]({
          message: "Password reset successfully",
          duration: 2,
        });
      })

      .catch((error) => {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};

/*----------------------*/

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS,
  };
};

export const fetchError = (error) => {
  return {
    type: FETCH_ERROR,
    payload: error,
  };
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

//
