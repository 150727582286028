import React from "react";
import { Menu, Alert, Row, Col } from "antd";
import {
  UserOutlined,
  DatabaseOutlined,
  CreditCardOutlined,
  BarChartOutlined,
  UnorderedListOutlined,
  AccountBookOutlined,
  CarOutlined,
  ShoppingCartOutlined,
  CarryOutOutlined,
  UnlockOutlined,
  SettingOutlined,
  UsergroupDeleteOutlined,
  AreaChartOutlined,
  UserAddOutlined,
  QuestionCircleOutlined,
  MenuOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  onNavStyleChange,
  toggleCollapsedSideNav,
} from "appRedux/actions/Setting";
import Translate from "../../appRedux/hooks/useTranslation";
import {
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
  NAV_STYLE_FIXED,
  NAV_STYLE_DRAWER,
  NAV_STYLE_MINI_SIDEBAR,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import HasPermission from "../../appRedux/hooks/useDashboardSettings";
import useUser from "../../appRedux/hooks/useUser";
import Scrollbars from "react-custom-scrollbars";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = () => {
  const dispatch = useDispatch();
  const { navCollapsed } = useSelector(({ settings }) => settings);

  let { navStyle, themeType, pathname } = useSelector(
    ({ settings }) => settings
  );

  const authUser = useSelector(({ auth }) => auth.authUser);

  let [fetchingUser, auth] = useUser();
  if (!auth) {
    auth = authUser;
  }

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  if (!auth) {
    return (
      <div className="container">
        <Alert
          type="error"
          message="Could not fetch sidebar content"
          description="Please make sure you have a working internet connection and refresh the page"
        />
      </div>
    );
  }

  const handleNavCollapse = () => {
    if (navStyle === NAV_STYLE_DRAWER) {
      dispatch(toggleCollapsedSideNav(!navCollapsed));
    } else if (navStyle === NAV_STYLE_FIXED) {
      dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR));
    } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      dispatch(toggleCollapsedSideNav(!navCollapsed));
    } else {
      dispatch(onNavStyleChange(NAV_STYLE_FIXED));
    }
  };

  return (
    <>
      <Row style={{ paddingTop: 15, paddingLeft: 25, height: 55 }} gutter={24}>
        <Col span={1} onClick={handleNavCollapse}>
          <MenuOutlined
            onClick={handleNavCollapse}
            style={{
              paddingTop: 8,
              marginLeft: 5,
              fontSize: "22px",
              color: "#ffffff",
              storke: "#ffffff",
            }}
          />
        </Col>
        <Col span={13} style={{ paddingTop: 1 }}>
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <Menu.Item key="DashboardPermissionGroups">
              <Link to="/data/dashboard">
                <span>
                  <Translate
                    id="dashboard"
                    default_value="Dashboard"
                    style={{
                      fontSize: "42px",
                      color: "#ffffff",
                      storke: "#ffffff",
                    }}
                  />
                </span>
              </Link>
            </Menu.Item>
          </Menu>
        </Col>
      </Row>
      <hr style={{ height: 2 }} />
      <Scrollbars
        className="track-vertical"
        style={{
          height: "96%",
          overflow: "auto",
        }}
      >
        <Menu
          defaultOpenKeys={[defaultOpenKeys]}
          selectedKeys={[selectedKeys]}
          theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
          mode="inline"
        >
          <SubMenu
            key="main"
            className="gx-menu-group"
            title={
              <span>
                <i className="icon icon-dasbhoard" />
                <span style={{ fontSize: 18 }}>
                  <Translate id="users" default_value="Users" />
                </span>
              </span>
            }
          >
            {HasPermission(auth, "list_admins") && (
              <Menu.Item key="components/administrators">
                <Link to="/data/administrators">
                  <UsergroupDeleteOutlined style={{ fontSize: "20px" }} />
                  <span>
                    <Translate id="administrators" />
                  </span>
                </Link>
              </Menu.Item>
            )}
            {HasPermission(auth, "list_lenders") && (
              <Menu.Item key="components/lenders">
                <Link to="/data/lenders">
                  <UsergroupDeleteOutlined style={{ fontSize: "20px" }} />
                  <span>
                    <Translate id="lenders" />
                  </span>
                </Link>
              </Menu.Item>
            )}
            {HasPermission(auth, "list_portal_users") && (
              <Menu.Item key="components/portal-users">
                <Link to="/data/portal-users">
                  <UsergroupDeleteOutlined style={{ fontSize: "20px" }} />
                  <span>
                    <Translate id="portal_users" default_value="Portal Users" />
                  </span>
                </Link>
              </Menu.Item>
            )}
            {HasPermission(auth, "list_respondents") && (
              <Menu.Item key="components/researchers">
                <Link to="/data/researchers">
                  <UsergroupDeleteOutlined style={{ fontSize: "20px" }} />
                  <span>
                    <Translate id="respondents" default_value="Respondents" />
                  </span>
                </Link>
              </Menu.Item>
            )}
            {HasPermission(auth, "list_financial_institutions") && (
              <Menu.Item key="components/financial_institutions">
                <Link to="/data/financial_institutions">
                  <UsergroupDeleteOutlined style={{ fontSize: "20px" }} />
                  <span>
                    <Translate id="financial_institutions" />
                  </span>
                </Link>
              </Menu.Item>
            )}
            {HasPermission(auth, "list_clients") && (
              <Menu.Item key="components/clients">
                <Link to="/data/clients">
                  <UsergroupDeleteOutlined style={{ fontSize: "20px" }} />
                  <span>
                    <Translate id="clients" />
                  </span>
                </Link>
              </Menu.Item>
            )}
            {auth !== null && auth.profile.role === "client" ? (
              <Menu.Item key="components/client-managers">
                <Link to="/data/client-managers">
                  <UsergroupDeleteOutlined style={{ fontSize: "20px" }} />
                  <span>
                    <Translate
                      id="client_managers"
                      default_value="Client Managers"
                    />
                  </span>
                </Link>
              </Menu.Item>
            ) : (
              ""
            )}

            {HasPermission(auth, "list_surveyors") && (
              <Menu.Item key="data/surveyors">
                <Link to="/data/surveyor">
                  <UsergroupDeleteOutlined style={{ fontSize: "20px" }} />
                  <span>
                    <Translate id="surveyors" default_value="Surveyors" />
                  </span>
                </Link>
              </Menu.Item>
            )}
            {HasPermission(auth, "list_employers") && (
              <Menu.Item key="data/employers">
                <Link to="/data/employers">
                  <UsergroupDeleteOutlined style={{ fontSize: "20px" }} />
                  <span>
                    <Translate id="employers" />
                  </span>
                </Link>
              </Menu.Item>
            )}
          </SubMenu>
          <MenuItemGroup
            key="user_profile"
            className="gx-menu-group"
            title={<Translate id="user_profile" default_value="User Profile" />}
          >
            {HasPermission(auth, "list_dashboard_permission_groups") && (
              <Menu.Item key="DashboardPermissionGroups">
                <Link to="/data/permission-groups">
                  <UnlockOutlined style={{ fontSize: "20px" }} />
                  <span>
                    <Translate
                      id="DasboardPermissionGroups"
                      default_value="Permission Groups"
                    />
                  </span>
                </Link>
              </Menu.Item>
            )}
            {HasPermission(auth, "list_app_settings") && (
              <Menu.Item key="AppSettings">
                <Link to="/data/app-settings">
                  <SettingOutlined style={{ fontSize: "20px" }} />
                  <span>
                    <Translate id="AppSettings" default_value="App Settings" />
                  </span>
                </Link>
              </Menu.Item>
            )}
            {HasPermission(auth, "portal_settings") && (
              <Menu.Item key="PortalSettings">
                <Link to="/data/portal-settings">
                  <SettingOutlined style={{ fontSize: "20px" }} />
                  <span>
                    <Translate
                      id="PortalSettings"
                      default_value="Portal Settings"
                    />
                  </span>
                </Link>
              </Menu.Item>
            )}
            {HasPermission(auth, "list_demographics") && (
              <Menu.Item key="demographics">
                <Link to="/data/demographics">
                  <i className="icon icon-copy" />
                  <span>
                    <Translate id="demographics" default_value="Demographics" />
                  </span>
                </Link>
              </Menu.Item>
            )}

            <SubMenu
              key="employee"
              className="gx-menu-group"
              title={
                <span>
                  <i className="icon icon-user" />
                  <span>
                    <Translate id="employee" default_value="Employees" />
                  </span>
                </span>
              }
            >
              {HasPermission(auth, "list_employee_attributes") && (
                <Menu.Item key="employeeform">
                  <Link to="/data/employee/attribute">
                    <UserOutlined style={{ fontSize: "20px" }} />
                    <Translate
                      style={{
                        paddingLeft: 10,
                      }}
                      id="employeeform"
                      default_value="Employee Attributes"
                    />
                  </Link>
                </Menu.Item>
              )}

              <Menu.Item key="employees">
                <Link to="/data/employees/data">
                  <UsergroupDeleteOutlined style={{ fontSize: "20px" }} />
                  Data
                </Link>
              </Menu.Item>

              {HasPermission(auth, "list_employee_attributes") && (
                <Menu.Item key="scheduled_employees">
                  <Link to="/data/employees/scheduled_data">
                    <ScheduleOutlined style={{ fontSize: "20px" }} />
                    Scheduled Data
                  </Link>
                </Menu.Item>
              )}
              {HasPermission(auth, "list_employee_attributes") && (
                <Menu.Item key="employees_reports">
                  <Link to="/data/employees/reports">
                    <BarChartOutlined style={{ fontSize: "20px" }} />
                    Generated Reports
                  </Link>
                </Menu.Item>
              )}
            </SubMenu>
          </MenuItemGroup>
          <MenuItemGroup
            key="user_profile"
            className="gx-menu-group"
            title="Employment Questions"
          >
            {HasPermission(auth, "list_employment_questions") && (
              <Menu.Item key="employement">
                <Link to="/data/employment">
                  <QuestionCircleOutlined style={{ fontSize: "20px" }} />
                  <Translate id="employment_questions" />
                </Link>
              </Menu.Item>
            )}
          </MenuItemGroup>
          <MenuItemGroup
            key="loan_management"
            className="gx-menu-group"
            title="Loan Management"
          >
            {HasPermission(auth, "list_loan_questions") && (
              <Menu.Item key="loan">
                <Link to="/data/loan">
                  <i className="icon icon-copy" />
                  <span>
                    <Translate id="loan_questions" />
                  </span>
                </Link>
              </Menu.Item>
            )}
          </MenuItemGroup>
          <MenuItemGroup
            key="tracking"
            className="gx-menu-group"
            title={<IntlMessages id="sidebar.tracking" />}
          >
            {HasPermission(auth, "view_combined_information") && (
              <Menu.Item key="combined">
                <Link to="/data/combined-usage-information">
                  <AreaChartOutlined style={{ fontSize: "20px" }} />
                  <span>
                    <Translate
                      id="sidebar.combined"
                      default_value="Combined Information"
                    />
                  </span>
                </Link>
              </Menu.Item>
            )}{" "}
            {HasPermission(auth, "view_user_specific_information") && (
              <Menu.Item key="user_specific">
                <Link to="/data/user_specific_data">
                  <AreaChartOutlined style={{ fontSize: "20px" }} />
                  <span>
                    <Translate
                      id="sidebar.user_specific"
                      default_value="User Specific Information"
                    />
                  </span>
                </Link>
              </Menu.Item>
            )}
          </MenuItemGroup>
          <MenuItemGroup
            key="notifications"
            className="gx-menu-group"
            title={
              <Translate
                id="sidebar.notifications"
                default_value="Notifications"
              />
            }
          >
            {HasPermission(auth, "list_notifications") && (
              <Menu.Item key="notification">
                <Link to="/data/notifications">
                  <i className="icon icon-message" />
                  <span>
                    <Translate
                      id="sidebar.notification"
                      default_value="Notifications"
                    />
                  </span>
                </Link>
              </Menu.Item>
            )}
          </MenuItemGroup>
          <MenuItemGroup
            key="financial_data"
            className="gx-menu-group"
            title={
              <Translate id="financial_data" default_value="Financial Data" />
            }
          >
            <SubMenu
              key="income"
              className="gx-menu-group"
              title={
                <span>
                  {" "}
                  <i className="icon icon-dasbhoard" />
                  <span>
                    <Translate id="income" default_value="Income" />
                  </span>
                </span>
              }
            >
              {HasPermission(auth, "list_agriculture_types") && (
                <Menu.Item key="components/agriculture_types">
                  <Link to="/data/agriculture_types">
                    <UnorderedListOutlined style={{ fontSize: "20px" }} />
                    <span>
                      <Translate
                        id="agriculture_types"
                        default_value="Agriculture Types"
                      />
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {HasPermission(auth, "list_business_types") && (
                <Menu.Item key="Business">
                  <Link to="/data/business_types">
                    <UnorderedListOutlined style={{ fontSize: "20px" }} />
                    <span>
                      <Translate
                        id="business_types"
                        default_value="Business Types"
                      />
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {HasPermission(auth, "list_employment_types") && (
                <Menu.Item key="employement-type">
                  <Link to="/data/employment_types">
                    <UnorderedListOutlined style={{ fontSize: "20px" }} />
                    <span>
                      <Translate
                        id="employment_types"
                        default_value="Employment Types"
                      />
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {HasPermission(auth, "list_non_work_income_types") && (
                <Menu.Item key="no-non_work_income_types">
                  <Link to="/data/non_work_income_types">
                    <UnorderedListOutlined style={{ fontSize: "20px" }} />
                    <span>
                      <Translate
                        id="non_work_income_types"
                        default_value="Non Work Income Types"
                      />
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {HasPermission(auth, "list_ownership_types") && (
                <Menu.Item key="ownership">
                  <Link to="/data/ownership">
                    <UserOutlined style={{ fontSize: "20px" }} />
                    <span>
                      <Translate
                        id="ownership_types"
                        default_value="Ownership Types"
                      />
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {/* Check this type */}
              {HasPermission(auth, "export_income_report") && (
                <Menu.Item key="data">
                  <Link to="/data/income/data">
                    <DatabaseOutlined style={{ fontSize: "20px" }} />
                    <span>
                      <Translate id="data" default_value="Data" />
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {/* Check this type */}
              {HasPermission(auth, "export_income_report") && (
                <Menu.Item key="scheduled_data">
                  <Link to="/data/income/scheduled_data">
                    <DatabaseOutlined style={{ fontSize: "20px" }} />
                    <span>
                      <Translate
                        id="scheduled_data"
                        default_value="Scheduled Data"
                      />
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {HasPermission(auth, "view_generated_reports") && (
                <Menu.Item key="generated_reports">
                  <Link to="/data/income/reports">
                    <BarChartOutlined style={{ fontSize: "20px" }} />
                    <span>Generated Reports</span>
                  </Link>
                </Menu.Item>
              )}
            </SubMenu>
            <SubMenu
              key="expense"
              className="gx-menu-group"
              title={
                <span>
                  {" "}
                  <i className="icon icon-dasbhoard" />
                  <span>
                    <Translate id="expenses" default_value="Expenses" />
                  </span>
                </span>
              }
            >
              {HasPermission(auth, "list_expense_types") && (
                <Menu.Item key="expenseTypes">
                  <Link to="/data/expense_types">
                    <CarryOutOutlined style={{ fontSize: "20px" }} />
                    <span>
                      <Translate
                        id="expense_types"
                        default_value="Expense Type"
                      />
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {HasPermission(auth, "list_cost_types") && (
                <Menu.Item key="costTypes">
                  <Link to="/data/cost_types">
                    <ShoppingCartOutlined style={{ fontSize: "20px" }} />
                    <span>
                      <Translate id="cost_types" default_value="Cost Types" />
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {HasPermission(auth, "export_expense_report") && (
                <Menu.Item key="data">
                  <Link to="/data/expenses/data">
                    <DatabaseOutlined style={{ fontSize: "20px" }} />
                    <span>
                      <Translate id="data" default_value="Data" />
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {HasPermission(auth, "export_scheduled_expense_report") && (
                <Menu.Item key="scheduled_data">
                  <Link to="/data/expenses/scheduled_data">
                    <DatabaseOutlined style={{ fontSize: "20px" }} />
                    <span>
                      <Translate
                        id="scheduled_data"
                        default_value="Scheduled Data"
                      />
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {HasPermission(auth, "view_generated_reports") && (
                <Menu.Item key="generated_reports">
                  <Link to="/data/expenses/reports">
                    <BarChartOutlined style={{ fontSize: "20px" }} />
                    <span>Generated Reports</span>
                  </Link>
                </Menu.Item>
              )}
            </SubMenu>
            <SubMenu
              key="savings"
              className="gx-menu-group"
              title={
                <span>
                  {" "}
                  <i className="icon icon-dasbhoard" />
                  <span>
                    <Translate id="savings" default_value="Accounts" />
                  </span>
                </span>
              }
            >
              {HasPermission(auth, "list_saving_tools") && (
                <Menu.Item key="saving_tools">
                  <Link to="/data/saving_tools">
                    <AccountBookOutlined style={{ fontSize: "20px" }} />
                    <span>
                      <Translate
                        id="saving_tools"
                        default_value="Account Tools"
                      />
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {HasPermission(auth, "export_saving_report") && (
                <Menu.Item key="data">
                  <Link to="/data/savings/data">
                    <DatabaseOutlined style={{ fontSize: "20px" }} />
                    <span>
                      <Translate id="data" default_value="Data" />
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {HasPermission(auth, "export_scheduled_saving_report") && (
                <Menu.Item key="scheduled_data">
                  <Link to="/data/savings/scheduled_data">
                    <DatabaseOutlined style={{ fontSize: "20px" }} />
                    <span>
                      <Translate
                        id="scheduled_data"
                        default_value="Scheduled Data"
                      />
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {HasPermission(auth, "view_generated_reports") && (
                <Menu.Item key="generated_reports">
                  <Link to="/data/savings/reports">
                    <BarChartOutlined style={{ fontSize: "20px" }} />
                    <span>Generated Reports</span>
                  </Link>
                </Menu.Item>
              )}
            </SubMenu>
            <SubMenu
              key="credit"
              className="gx-menu-group"
              title={
                <span>
                  {" "}
                  <i className="icon icon-dasbhoard" />
                  <span>
                    <Translate id="credits" default_value="Credits" />
                  </span>
                </span>
              }
            >
              {HasPermission(auth, "list_loan_tools") && (
                <Menu.Item key="loan_tools">
                  <Link to="/data/loan_tools">
                    <CreditCardOutlined style={{ fontSize: "20px" }} />
                    <span>
                      <Translate id="loan_tools" default_value="Loan Tools" />
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {HasPermission(auth, "export_credit_report") && (
                <Menu.Item key="credits_data">
                  <Link to="/data/credits/data">
                    <DatabaseOutlined style={{ fontSize: "20px" }} />
                    <span>
                      <Translate id="data" default_value="Data" />
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {HasPermission(auth, "export_scheduled_credit_report") && (
                <Menu.Item key="scheduled_credits_data">
                  <Link to="/data/credits/scheduled_data">
                    <DatabaseOutlined style={{ fontSize: "20px" }} />
                    <span>
                      <Translate
                        id="scheduled_data"
                        default_value="Scheduled Data"
                      />
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {HasPermission(auth, "view_generated_reports") && (
                <Menu.Item key="credits_generated_reports">
                  <Link to="/data/credits/reports">
                    <BarChartOutlined style={{ fontSize: "20px" }} />
                    <span>Generated Reports</span>
                  </Link>
                </Menu.Item>
              )}
            </SubMenu>
            <SubMenu
              key="asset"
              className="gx-menu-group"
              title={
                <span>
                  {" "}
                  <i className="icon icon-dasbhoard" />
                  <span>
                    <Translate id="assets" default_value="Assets" />
                  </span>
                </span>
              }
            >
              {HasPermission(auth, "list_asset_types") && (
                <Menu.Item key="assetTypes">
                  <Link to="/data/asset_types">
                    <CarOutlined style={{ fontSize: "20px" }} />
                    <span>
                      <Translate id="asset_types" default_value="Asset Types" />
                    </span>
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item key="data">
                <Link to="/data/assets/data">
                  <DatabaseOutlined style={{ fontSize: "20px" }} />
                  <span>
                    <Translate id="data" default_value="Data" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="scheduled_data">
                <Link to="/data/assets/scheduled_data">
                  <DatabaseOutlined style={{ fontSize: "20px" }} />
                  <span>
                    <Translate
                      id="scheduled_data"
                      default_value="Scheduled Data"
                    />
                  </span>
                </Link>
              </Menu.Item>
              {HasPermission(auth, "view_generated_reports") && (
                <Menu.Item key="assets_generated_reports">
                  <Link to="/data/assets/reports">
                    <BarChartOutlined style={{ fontSize: "20px" }} />
                    <span>Generated Reports</span>
                  </Link>
                </Menu.Item>
              )}
            </SubMenu>
          </MenuItemGroup>
          <MenuItemGroup
            key="survey_management"
            className="gx-menu-group"
            title={
              <Translate
                id="survey_management"
                default_value="Survey Management"
              />
            }
          >
            {HasPermission(auth, "list_projects") && (
              <Menu.Item key="projects">
                <Link to="/data/projects">
                  <UnorderedListOutlined style={{ fontSize: "20px" }} />
                  <span>
                    <Translate id="projects" default_value="Projects" />
                  </span>
                </Link>
              </Menu.Item>
            )}
            {HasPermission(auth, "list_surveys") && (
              <Menu.Item key="surveys">
                <Link to="/data/surveys">
                  <AreaChartOutlined style={{ fontSize: "20px" }} />
                  <span>
                    <Translate id="survey" default_value="Surveys" />
                  </span>
                </Link>
              </Menu.Item>
            )}
          </MenuItemGroup>{" "}
          <MenuItemGroup
            key="settings"
            className="gx-menu-group"
            title={<Translate id="settings" default_value="Settings" />}
          >
            <SubMenu
              key="Language Management"
              className="gx-menu-group"
              title={
                <span>
                  {" "}
                  <i className="icon icon-dasbhoard" />
                  <span>
                    <Translate id="language" />
                  </span>
                </span>
              }
            >
              {HasPermission(auth, "list_languages") && (
                <Menu.Item key="languages">
                  <Link to="/data/language">
                    <i className="icon icon-copy" />
                    <span>
                      <Translate id="languages" />
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {HasPermission(auth, "view_translations") && (
                <Menu.Item key="translations">
                  <Link to="/data/translation">
                    <i className="icon icon-copy" />
                    <span>
                      <Translate id="translations" />
                    </span>
                  </Link>
                </Menu.Item>
              )}
            </SubMenu>
            <hr />
          </MenuItemGroup>
          <MenuItemGroup key="settings" className="gx-menu-group" />
        </Menu>
      </Scrollbars>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
