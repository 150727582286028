import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Col, Popover, Row, Typography } from "antd";
import { SignOut } from "appRedux/actions/Auth";
import { Link } from "react-router-dom";
import Translate from "../../appRedux/hooks/useTranslation";
import {
  DownOutlined,
  KeyOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";

const { Text } = Typography;

const UserInfo = () => {
  const authUser = useSelector(({ auth }) => auth.authUser);
  const dispatch = useDispatch();

  const userMenuOptions = (
    <ul className="gx-user-popover" style={{ margin: 4 }}>
      <Link style={{ color: "#424040" }} to="/data/profile">
        <UserOutlined style={{ fontSize: "20px" }} />
        <span style={{ paddingLeft: 10 }}>
          {<Translate id="AppSettings" default_value="Profile" />}
        </span>
      </Link>
      <hr />
      <Link style={{ color: "#424040" }} to="/data/change-password">
        <KeyOutlined style={{ fontSize: "16px" }} />
        <span style={{ paddingLeft: 10 }}>
          {
            <Translate
              id="change_password"
              default_value="Change Password"
              style={{ paddingLeft: "50px" }}
            />
          }
        </span>
      </Link>
      <hr />
      <Link
        style={{ color: "#424040" }}
        onClick={() => dispatch(SignOut())}
        to={"#"}
      >
        <LogoutOutlined style={{ fontSize: "16px" }} />
        <span style={{ paddingLeft: 10 }}> Log out </span>
      </Link>
    </ul>
  );

  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    >
      <Row style={{ padding: 10 }}>
        <Col>
          <Avatar
            src={
              authUser?.profile_picture
                ? authUser?.profile_picture
                : require("assets/images/avatar/finbit_admin_avatar.png")
            }
            className="gx-avatar gx-pointer"
            alt=""
          />
        </Col>
        <Col style={{ flex: 1, paddingLeft: 5, paddingTop: 7 }}>
          <Text level={5} style={{ margin: 0, fontSize: 16 }}>
            {authUser?.name}
          </Text>
          <br />

          {authUser && authUser.profile && (
            <Text level={5} style={{ margin: 0, fontSize: 13 }}>
              {authUser.profile.role === "client"
                ? "Client"
                : authUser.profile.role === "client_manager"
                ? "Client Manager"
                : authUser.profile.role === "admin"
                ? "Admin"
                : ""}
            </Text>
          )}
          <DownOutlined style={{ marginLeft: 5, marginTop: 2, fontSize: 12 }} />
        </Col>
      </Row>
    </Popover>
  );
};

export default UserInfo;
