import { Button, Form, Input } from "antd";

import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import IntlMessages from "../util/IntlMessages";
import { resetPassword } from "../appRedux/actions/Auth";

const FormItem = Form.Item;

const ForgotPassword = (props) => {
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        dispatch(resetPassword(values));
      }
    });
  };

  const { getFieldDecorator } = props.form;
  var bg = require("../assets/images/bg.jpg");

  return (
    <div
      className="gx-app-login-wrap"
      style={{ backgroundImage: "url(" + bg + ")" }}
    >
      <div className="gx-login-container">
        <div className="gx-login-content">
          <div className="gx-login-header">
            <img
              src={require("../assets/images/finbit.png")}
              style={{
                height: "auto",
                width: "auto",
                "max-width": 350,
                "max-height": 200,
                marginLeft: 40,
              }}
              alt="FINBIT Admin"
              title="FINBIT"
            />
          </div>
          <div className="gx-mb-4">
            <h2>Forgot Your Password ?</h2>
            <p>
              <IntlMessages id="app.userAuth.forgot" />
            </p>
          </div>

          <Form
            layout="vertical"
            onSubmit={handleSubmit}
            className="gx-login-form gx-form-row0"
          >
            <FormItem>
              {getFieldDecorator("email", {
                rules: [
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ],
              })(
                <Input size="large" type="email" placeholder="E-Mail Address" />
              )}
            </FormItem>

            <FormItem>
              <Button type="primary" htmlType="submit">
                <IntlMessages id="app.userAuth.send" />
              </Button>

              <Link to="/signin" style={{ marginLeft: 120 }}>
                back to Sign In
              </Link>
            </FormItem>
          </Form>
        </div>
      </div>
    </div>
  );
};

const WrappedForgotPasswordForm = Form.create()(ForgotPassword);

export default WrappedForgotPasswordForm;
