import {
  SWITCH_LANGUAGE,
  SWITCH_CURRENT_LANGUAGE,
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH,
  FETCH_START,
  FETCH_SUCCESS,
  GET_TRANSLATION,
} from "../../constants/ActionTypes";
import {
  LAYOUT_TYPE,
  NAV_STYLE,
  THEME_COLOR,
  THEME_TYPE,
} from "../../constants/ThemeSetting";
import HandleError from "../hooks/useErrorHandler";
import { notification } from "antd";

import axios from "../../util/Api";
export function toggleCollapsedSideNav(navCollapsed) {
  return { type: TOGGLE_COLLAPSED_NAV, navCollapsed };
}

export function updateWindowWidth(width) {
  return (dispatch) => {
    dispatch({ type: WINDOW_WIDTH, width });
  };
}

export function setThemeType(themeType) {
  return (dispatch) => {
    dispatch({ type: THEME_TYPE, themeType });
  };
}

export function setThemeColor(themeColor) {
  return (dispatch) => {
    dispatch({ type: THEME_COLOR, themeColor });
  };
}

export function onNavStyleChange(navStyle) {
  return (dispatch) => {
    dispatch({ type: NAV_STYLE, navStyle });
  };
}

export function onLayoutTypeChange(layoutType) {
  return (dispatch) => {
    dispatch({ type: LAYOUT_TYPE, layoutType });
  };
}

export function switchLanguage(locale) {
  return (dispatch) => {
    dispatch({
      type: SWITCH_LANGUAGE,
      payload: locale,
    });
  };
}

export const switchCurrentLanguage = (language) => {
  return (dispatch) => {
    localStorage.setItem("selectedLanguage", JSON.stringify(language));
    dispatch({
      type: SWITCH_CURRENT_LANGUAGE,
      payload: language,
    });
  };
};

export const fetchTranslation = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    console.log("fetching translation");
    axios
      .get("/translation/?type=admin")
      .then(({ data }) => {
        console.log("translation: ", data);
        if (data) {
          localStorage.setItem("translation", JSON.stringify(data));
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_TRANSLATION, payload: data });
        } else {
          console.log("translation not downloaded");
        }
      })
      .catch(function (error) {
        HandleError(error).map((errorMessage) => {
          notification["error"]({
            message: errorMessage,
            duration: 2,
          });
        });
      });
  };
};
