import React, { useEffect } from "react";
import { Layout, Popover } from "antd";
import CustomScrollbars from "util/CustomScrollbars";
import useLanguages from "../../appRedux/hooks/useLanguages";
import {
  switchCurrentLanguage,
  toggleCollapsedSideNav,
} from "../../appRedux/actions/Setting";
import { AreaChartOutlined, UnorderedListOutlined } from "@ant-design/icons";
import UserInfo from "components/UserInfo";
import Auxiliary from "util/Auxiliary";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";

const { Header } = Layout;

const IconLink = ({ icon, text, link }) => (
  <a className="example-link" href={link} style={{ marginRight: 70 }}>
    <span style={{ marginRight: 5 }}>{icon}</span>
    <span style={{ fontSize: 18 }}>{text}</span>
  </a>
);

const Topbar = () => {
  const { current_language, width, navCollapsed, navStyle } = useSelector(
    ({ settings }) => settings
  );
  const [fetchingLanguages, Languages] = useLanguages();
  const dispatch = useDispatch();

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      dispatch(switchCurrentLanguage(JSON.parse(storedLanguage)));
    }
  }, [dispatch]);

  var LanguageData = [];

  if (!fetchingLanguages && Languages) {
    LanguageData = Languages.map((c) => ({
      label: c.language,
      short_code: c.short_code,
      flag:
        c.short_code === "EN"
          ? "gb"
          : c.short_code === "AM"
          ? "et"
          : c.short_code === "BZ"
          ? "mm"
          : c.short_code === "MY"
          ? "mm"
          : c.short_code === "FR"
          ? "fr"
          : c.short_code === "MY"
          ? "mm"
          : c.short_code === "BZ"
          ? "mm"
          : c.short_code === "BH"
          ? "id"
          : c.short_code === "HI"
          ? "in"
          : c.short_code === "BN"
          ? "bd"
          : c.short_code === "KC"
          ? "cd"
          : c.short_code === "AO"
          ? "et"
          : c.short_code === "SP"
          ? "es"
          : c.short_code === "GJ"
          ? "in"
          : c.short_code === "TL"
          ? "in"
          : c.short_code === "LG"
          ? "ug"
          : c.short_code === "FJ"
          ? "fj"
          : c.short_code === "KH"
          ? "kh"
          : c.short_code === "KT"
          ? "tz"
          : c.short_code === "ZM"
          ? "zm"
          : "gb",
    }));
  }

  const handleLanguageChange = (language) => {
    dispatch(switchCurrentLanguage(language));
    localStorage.setItem("selectedLanguage", JSON.stringify(language));
  };

  const LanguageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {LanguageData.map((language) => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={() => handleLanguageChange(language)}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.flag}`} />
            <span className="gx-language-text">{language.label}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        width < TAB_SIZE) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}
      <div>
        <IconLink
          icon={<AreaChartOutlined style={{ fontSize: 20 }} />}
          text="Surveys"
          link={`#/data/surveys`}
        />
        <IconLink
          icon={<UnorderedListOutlined style={{ fontSize: 20 }} />}
          text="Projects"
          link={`#/data/projects`}
        />
      </div>

      <ul className="gx-header-notifications gx-ml-auto">
        <li className="gx-language"></li>
        <li className="gx-language">
          <Popover
            overlayClassName="gx-popover-horizantal"
            placement="bottomRight"
            content={LanguageMenu()}
            trigger="click"
          >
            <span className="gx-pointer gx-flex-row gx-align-items-center">
              <i
                className={
                  current_language.flag
                    ? `flag flag-24 flag-${current_language.flag}`
                    : `flag flag-24 flag-gb`
                }
              />
              <span className="gx-pl-2 gx-language-name">
                {current_language.label}
              </span>
              <i className="icon icon-chevron-down gx-pl-2" />
            </span>
          </Popover>
        </li>

        <Auxiliary>
          <li className="gx-user-nav">
            <UserInfo />
          </li>
        </Auxiliary>
      </ul>
    </Header>
  );
};

export default Topbar;
