import {
  DELETE_CONTACT_SUCCESS,
  DELETE_USER_SUCCESS,
  GET_All_CONTACT_SUCCESS,
  GET_All_USER_SUCCESS,
  ON_ADD_CONTACT_SUCCESS,
  ON_ADD_USER_SUCCESS,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_USER_SUCCESS,
  GET_All_RESEARCHERS_SUCCESS,
  GET_EXPORT_SUCCESS,
  GET_CHECK_EXPORT_SUCCESS,
  GET_ATTACHMENT_EXPORT_SUCCESS,
  GET_ATTACHMENT_CHECK_EXPORT_SUCCESS,
  GET_INCOME_EXPORT_SUCCESS,
  GET_INCOME_CHECK_EXPORT_SUCCESS,
  GET_SAVING_EXPORT_SUCCESS,
  GET_SAVING_CHECK_EXPORT_SUCCESS,
  GET_EXPENSE_EXPORT_SUCCESS,
  GET_EXPENSE_CHECK_EXPORT_SUCCESS,
  GET_EMPLOYEE_EXPORT_SUCCESS,
  GET_EMPLOYEE_CHECK_EXPORT_SUCCESS,
  GET_CREDIT_EXPORT_SUCCESS,
  GET_CREDIT_CHECK_EXPORT_SUCCESS,
  GET_FILTERED_RESEARCHERS_SUCCESS,
  GET_STAT_SUCCESS,

  GET_All_ADMIN_USER_SUCCESS,
  ON_ADD_ADMIN_USER_SUCCESS,
  DELETE_ADMIN_USER_SUCCESS,
  UPDATE_ADMIN_USER_SUCCESS,

  GET_All_LENDER_USER_SUCCESS,
  ON_ADD_LENDER_USER_SUCCESS,
  DELETE_LENDER_USER_SUCCESS,
  UPDATE_LENDER_USER_SUCCESS,

  GET_All_PORTAL_USER_SUCCESS,
  ON_ADD_PORTAL_USER_SUCCESS,
  DELETE_PORTAL_USER_SUCCESS,
  UPDATE_PORTAL_USER_SUCCESS,

  GET_All_RESEARCHER_USER_SUCCESS,
  ON_ADD_RESEARCHER_USER_SUCCESS,
  DELETE_RESEARCHER_USER_SUCCESS,
  UPDATE_RESEARCHER_USER_SUCCESS,

  GET_All_INSTITUTION_USER_SUCCESS,
  ON_ADD_INSTITUTION_USER_SUCCESS,
  DELETE_INSTITUTION_USER_SUCCESS,
  UPDATE_INSTITUTION_USER_SUCCESS,

  GET_All_CLIENT_USER_SUCCESS,
  ON_ADD_CLIENT_USER_SUCCESS,
  DELETE_CLIENT_USER_SUCCESS,
  UPDATE_CLIENT_USER_SUCCESS,

  GET_All_SURVEYOR_USER_SUCCESS,
  ON_ADD_SURVEYOR_USER_SUCCESS,
  DELETE_SURVEYOR_USER_SUCCESS,
  UPDATE_SURVEYOR_USER_SUCCESS,

  GET_All_EMPLOYER_USER_SUCCESS,
  ON_ADD_EMPLOYER_USER_SUCCESS,
  DELETE_EMPLOYER_USER_SUCCESS,
  UPDATE_EMPLOYER_USER_SUCCESS,

  GET_All_DEMOGRAPHIC_SUCCESS,
  ON_ADD_DEMOGRAPHIC_SUCCESS,
  DELETE_DEMOGRAPHIC_SUCCESS,
  UPDATE_DEMOGRAPHIC_SUCCESS,

  GET_All_EMPLOYEE_FORM_SUCCESS,
  ON_ADD_EMPLOYEE_FORM_SUCCESS,
  DELETE_EMPLOYEE_FORM_SUCCESS,
  UPDATE_EMPLOYEE_FORM_SUCCESS,

  GET_DEFAULT_APP_SETTINGS_SUCCESS,
  GET_APP_SETTINGS_SUCCESS,
  SET_SELECTED_APP_SETTING,
  ON_ADD_APP_SETTINGS_SUCCESS,
  DELETE_APP_SETTINGS_SUCCESS,
  UPDATE_APP_SETTINGS_SUCCESS,

  GET_PORTAL_SETTINGS_SUCCESS,

  GET_All_EMPLOYMENT_QUESTION_SUCCESS,
  ON_ADD_EMPLOYMENT_QUESTION_SUCCESS,
  DELETE_EMPLOYMENT_QUESTION_SUCCESS,
  UPDATE_EMPLOYMENT_QUESTION_SUCCESS,

  GET_All_LOAN_QUESTION_SUCCESS,
  ON_ADD_LOAN_QUESTION_SUCCESS,
  DELETE_LOAN_QUESTION_SUCCESS,
  UPDATE_LOAN_QUESTION_SUCCESS,

  GET_All_NOTIFICATION_SUCCESS,
  ON_ADD_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_SUCCESS,

  GET_All_ASSET_TYPE_SUCCESS,
  ON_ADD_ASSET_TYPE_SUCCESS,
  DELETE_ASSET_TYPE_SUCCESS,
  UPDATE_ASSET_TYPE_SUCCESS,

  GET_All_AGRICULTURE_TYPE_SUCCESS,
  ON_ADD_AGRICULTURE_TYPE_SUCCESS,
  DELETE_AGRICULTURE_TYPE_SUCCESS,
  UPDATE_AGRICULTURE_TYPE_SUCCESS,


  GET_All_BUSINESS_TYPE_SUCCESS,
  ON_ADD_BUSINESS_TYPE_SUCCESS,
  DELETE_BUSINESS_TYPE_SUCCESS,
  UPDATE_BUSINESS_TYPE_SUCCESS,

  GET_All_EMPLOYMENT_TYPE_SUCCESS,
  ON_ADD_EMPLOYMENT_TYPE_SUCCESS,
  DELETE_EMPLOYMENT_TYPE_SUCCESS,
  UPDATE_EMPLOYMENT_TYPE_SUCCESS,

  GET_All_OWNERSHIP_TYPE_SUCCESS,
  ON_ADD_OWNERSHIP_TYPE_SUCCESS,
  DELETE_OWNERSHIP_TYPE_SUCCESS,
  UPDATE_OWNERSHIP_TYPE_SUCCESS,

  GET_All_NON_WORK_INCOME_TYPE_SUCCESS,
  ON_ADD_NON_WORK_INCOME_TYPE_SUCCESS,
  DELETE_NON_WORK_INCOME_TYPE_SUCCESS,
  UPDATE_NON_WORK_INCOME_TYPE_SUCCESS,

  GET_All_EXPENSE_TYPE_SUCCESS,
  ON_ADD_EXPENSE_TYPE_SUCCESS,
  DELETE_EXPENSE_TYPE_SUCCESS,
  UPDATE_EXPENSE_TYPE_SUCCESS,

  GET_All_COST_TYPE_SUCCESS,
  ON_ADD_COST_TYPE_SUCCESS,
  DELETE_COST_TYPE_SUCCESS,
  UPDATE_COST_TYPE_SUCCESS,

  GET_All_SAVING_TOOLE_SUCCESS,
  ON_ADD_SAVING_TOOL_SUCCESS,
  DELETE_SAVING_TOOL_SUCCESS,
  UPDATE_SAVING_TOOL_SUCCESS,

  GET_All_LOAN_TOOLE_SUCCESS,
  ON_ADD_LOAN_TOOL_SUCCESS,
  DELETE_LOAN_TOOL_SUCCESS,
  UPDATE_LOAN_TOOL_SUCCESS,

  GET_All_LANGUAGE_SUCCESS,
  ON_ADD_LANGUAGE_SUCCESS,
  DELETE_LANGUAGE_SUCCESS,
  UPDATE_LANGUAGE_SUCCESS,

  GET_All_COMBINED_REPORT_DATA_SUCCESS,
  GET_All_USER_SPECIFIC_REPORT_DATA_SUCCESS,

  GET_All_SURVEY_DATA_SUCCESS,
  ON_ADD_SURVEY_DATA_SUCCESS,
  DELETE_SURVEY_DATA_SUCCESS,
  UPDATE_SURVEY_DATA_SUCCESS,
  GET_All_PROJECT_SUCCESS,
  ON_ADD_PROJECT_SUCCESS,
  DELETE_PROJECT_SUCCESS,
  UPDATE_PROJECT_SUCCESS,
  GET_GENERATED_REPORTS_SUCCESS,
  RESET_CREDIT_EXPORT_SUCCESS,
  RESET_ASSETS_EXPORT_SUCCESS,
  RESET_EMPLOYEE_EXPORT_SUCCESS,
  GET_LOAN_GIVEN_EXPORT_SUCCESS,
  RESET_LOAN_GIVEN_EXPORT_SUCCESS,
  RESET_LOAN_TAKEN_EXPORT_SUCCESS,
  RESET_EXPENSE_EXPORT_SUCCESS,
  RESET_INCOME_EXPORT_SUCCESS,
  RESET_SAVING_EXPORT_SUCCESS,
  RESET_SURVEY_EXPORT_SUCCESS,
  RESET_NOTIFICATION_EXPORT_SUCCESS,
  RESET_SURVEY_ATTACHMENT_EXPORT_SUCCESS,
  GET_PERMISSIONS_SUCCESS,
  GET_FINANCE_EXPORT_SUCCESS,
  GET_FINANCE_CHECK_EXPORT_SUCCESS,
  GET_NOTIFICATION_CHECK_EXPORT_SUCCESS,
  GET_ASSETS_EXPORT_SUCCESS,
  GET_ASSETS_CHECK_EXPORT_SUCCESS,
  GET_LOAN_GIVEN_CHECK_EXPORT_SUCCESS,
  GET_LOAN_TAKEN_EXPORT_SUCCESS,
  GET_LOAN_TAKEN_CHECK_EXPORT_SUCCESS,
  GET_NOTIFICATION_EXPORT_SUCCESS,
  GET_GENERATED_INCOME_REPORTS_SUCCESS,
  GET_GENERATED_EXPENSE_REPORTS_SUCCESS,
  GET_GENERATED_CREDIT_REPORTS_SUCCESS,
  GET_GENERATED_SAVING_REPORTS_SUCCESS,
  GET_All_EMPLOYEE_SURVEY_DATA_SUCCESS,
  ON_ADD_EMPLOYEE_SURVEY_DATA_SUCCESS,
  UPDATE_EMPLOYEE_SURVEY_DATA_SUCCESS,
  DELETE_EMPLOYEE_SURVEY_DATA_SUCCESS,
  GET_EMPLOYEE_SURVEY_GENERATED_REPORTS_SUCCESS,
  GET_All_CLIENT_MANAGERS_SUCCESS,
  ON_ADD_CLIENT_MANAGERS_SUCCESS,
  UPDATE_CLIENT_MANAGERS_SUCCESS,
  DELETE_CLIENT_MANAGERS_SUCCESS,
  GET_GENERATED_ASSET_REPORTS_SUCCESS,
  GET_GENERATED_EMPLOYEE_REPORTS_SUCCESS,
  GET_DEMOGRAPHICS_ORDER_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  ON_ADD_DEMOGRAPHIC_ORDER_SUCCESS,
  UPDATE_DEMOGRAPHIC_ORDER_SUCCESS,
  GET_GENERATED_LOAN_GIVEN_REPORTS_SUCCESS,
  GET_DOWNLOAD_LINK_SUCCESS,

} from "../../constants/ActionTypes";

const INIT_STATE = {
  contactList: [],
  userList: [],
  assetTypeUserList:[],
  selectedContact: [],
  surveyDataExport: {},
  surveyDataExportCheck: {},
  surveyAttachmentExport: {},
  surveyAttachmentExportCheck: {},
  incomeDataExport: {},
  incomeDataExportCheck: {},
  financeDataExport: {},
  financeDataExportCheck: {},
  savingDataExport: {},
  savingDataExportCheck: {},
  expenseDataExport: {},
  expenseDataExportCheck: {},
  notificationDataExport: {},
  notificationDataExportCheck: {},
  loanGivenDataExport: {},
  loanGivenDataExportCheck: {},
  loanTakenDataExport: {},
  loanTakenDataExportCheck: {},
  employeeDataExportCheck: {},
  employeeDataExport: {},
  creditDataExport: {},
  creditDataExportCheck: {},
  assetDataExport: {},
  assetDataExportCheck: {},
  defaultAppSetting: {},
  defaultPortalSetting: {},
  filteredResearchers: [],
  stats: {},
  adminUserList: [],
  lenderUserList: [],
  portalUserList: [],
  researcherUserList: [],
  appSettingsList: [],
  portalSettingsList: [],
  permissionsList: [],
  selectedAppSetting: {},
  selectedPortalSetting: {},
  institutionUserList: [],
  clientUserList: [],
  surveyorUserList: [],
  employerUserList: [],
  demographicUserList: [],
  employmentQUserList: [],
  loanQUserList: [],
  notificationUserList: [],
  agriTypeUserList: [],
  businessTypeUserList: [],
  employmentTypeUserList: [],
  ownershipTypeUserList: [],
  nonWorkIncomeUserList: [],
  expenseTypeUserList: [],
  costTypeUserList: [],
  savingToolUserList: [],
  loanToolUserList: [],
  languageUserList: [],
  combinedReportData: [],
  userSpecificReportData: [],
  surveyDataList: [],
  employeeSurveyDataList: [],
  generatedReports: [],
  employeeSurveyGeneratedReports: [],
  incomeGeneratedReports: [],
  expenseGeneratedReports: [],
  creditGeneratedReports: [],
  loanGivenGeneratedReports: [],
  savingGeneratedReports: [],
  assetGeneratedReports: [],
  employeeGeneratedReports: [],
  demographicOrder: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEFAULT_APP_SETTINGS_SUCCESS: {
      return {
        ...state,
        defaultAppSetting: action.payload,
      };
    }
    case GET_APP_SETTINGS_SUCCESS: {
      return {
        ...state,
        appSettingsList: action.payload,
      };
    }
    case GET_PORTAL_SETTINGS_SUCCESS: {
      return {
        ...state,
        portalSettingsList: action.payload,
      };
    }
    case GET_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        permissionsList: action.payload,
      };
    }

    case SET_SELECTED_APP_SETTING: {
      return {
        ...state,
        selectedAppSetting: action.payload,
      };
    }

    case GET_All_CONTACT_SUCCESS: {
      return {
        ...state,
        contactList: action.payload,
      };
    }

    case GET_STAT_SUCCESS: {
      return {
        ...state,
        stats: action.payload,
      };
    }

    case GET_All_USER_SUCCESS: {
      return {
        ...state,
        userList: action.payload,
      };
    }

    case GET_All_RESEARCHERS_SUCCESS: {
      return {
        ...state,
        researchers: action.payload,
      };
    }

    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        contactList: state.contactList.map((contact) =>
          contact.id === action.payload.id ? action.payload : contact
        ),
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        userList: state.userList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        contactList: state.contactList.filter(
          (contact) => contact.id !== action.payload.id
        ),
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        userList: state.userList.filter((user) => user.id !== action.payload),
      };

    case ON_ADD_CONTACT_SUCCESS:
      return {
        ...state,
        contactList: action.payload.contact(state.contactList),
      };
    case ON_ADD_USER_SUCCESS:
      return {
        ...state,
        userList: [...state.userList, action.payload],
      };

    case GET_EXPORT_SUCCESS:
      return {
        ...state,
        surveyDataExport: action.payload,
      };

    case GET_CHECK_EXPORT_SUCCESS:
      return {
        ...state,
        surveyDataExportCheck: action.payload,
      };

    case GET_ATTACHMENT_EXPORT_SUCCESS:
      return {
        ...state,
        surveyAttachmentExport: action.payload,
      };

    case GET_ATTACHMENT_CHECK_EXPORT_SUCCESS:
      return {
        ...state,
        surveyAttachmentExportCheck: action.payload,
      };

    case GET_INCOME_EXPORT_SUCCESS:
      return {
        ...state,
        incomeDataExport: action.payload,
      };

    case GET_INCOME_CHECK_EXPORT_SUCCESS:
      return {
        ...state,
        incomeDataExportCheck: action.payload,
      };
    case GET_FINANCE_EXPORT_SUCCESS:
      return {
        ...state,
        financeDataExport: action.payload,
      };

    case GET_FINANCE_CHECK_EXPORT_SUCCESS:
      return {
        ...state,
        financeDataExportCheck: action.payload,
      };
    case GET_SAVING_EXPORT_SUCCESS:
      return {
        ...state,
        savingDataExport: action.payload,
      };

    case GET_SAVING_CHECK_EXPORT_SUCCESS:
      return {
        ...state,
        savingDataExportCheck: action.payload,
      };
    case GET_EXPENSE_EXPORT_SUCCESS:
      return {
        ...state,
        expenseDataExport: action.payload,
      };

    case GET_EXPENSE_CHECK_EXPORT_SUCCESS:
      return {
        ...state,
        expenseDataExportCheck: action.payload,
      };

    case GET_NOTIFICATION_EXPORT_SUCCESS:
      return {
        ...state,
        notificationDataExport: action.payload,
      };

    case GET_NOTIFICATION_CHECK_EXPORT_SUCCESS:
      return {
        ...state,
        notificationDataExportCheck: action.payload,
      };
    case GET_ASSETS_EXPORT_SUCCESS:
      return {
        ...state,
        assetDataExport: action.payload,
      };

    case GET_ASSETS_CHECK_EXPORT_SUCCESS:
      return {
        ...state,
        assetDataExportCheck: action.payload,
      };

    case GET_EMPLOYEE_EXPORT_SUCCESS:
      return {
        ...state,
        employeeDataExport: action.payload,
      };

    case GET_EMPLOYEE_CHECK_EXPORT_SUCCESS:
      return {
        ...state,
        employeeDataExportCheck: action.payload,
      };
    case GET_CREDIT_EXPORT_SUCCESS:
      return {
        ...state,
        creditDataExport: action.payload,
      };

    case RESET_CREDIT_EXPORT_SUCCESS:
      return {
        ...state,
        creditDataExport: {},
        creditDataExportCheck: {}
      };

    case RESET_LOAN_GIVEN_EXPORT_SUCCESS:
      return {
        ...state,
        loanGivenDataExport: {},
        loanGivenDataExportCheck: {},
      };
    case RESET_LOAN_TAKEN_EXPORT_SUCCESS:
      return {
        ...state,
        loanTakenDataExport: {},
        loanTakenDataExportCheck: {},
      };

    case RESET_ASSETS_EXPORT_SUCCESS:
      return {
        ...state,
        assetDataExport: {},
        assetDataExportCheck: {},
      };

    case RESET_EMPLOYEE_EXPORT_SUCCESS:
      return {
        ...state,
        employeeDataExport: {},
        employeeDataExportCheck: {},
      };
    case RESET_EXPENSE_EXPORT_SUCCESS:
      return {
        ...state,
        expenseDataExport: {},
        expenseDataExportCheck: {},
      };

    case RESET_INCOME_EXPORT_SUCCESS:
      return {
        ...state,
        incomeDataExport: {},
        incomeDataExportCheck: {},
      };

    case RESET_SAVING_EXPORT_SUCCESS:
      return {
        ...state,
        savingDataExport: {},
        savingDataExportCheck: {},
      };

    case RESET_SURVEY_EXPORT_SUCCESS:
      return {
        ...state,
        surveyDataExport: {},
        surveyDataExportCheck: {},
      };
    case RESET_NOTIFICATION_EXPORT_SUCCESS:
      return {
        ...state,
        notificationDataExport: {},
        notificationDataExportCheck: {},
      };

    case RESET_SURVEY_ATTACHMENT_EXPORT_SUCCESS:
      return {
        ...state,
        surveyAttachmentExport: {},
        surveyAttachmentExportCheck: {},
      };
    case GET_LOAN_GIVEN_EXPORT_SUCCESS:
      return {
        ...state,
        loanGivenDataExport: action.payload,
      };
    case GET_LOAN_GIVEN_CHECK_EXPORT_SUCCESS:
      return {
        ...state,
        loanGivenDataExportCheck: action.payload,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        password_reset: action.payload,
      };
    case GET_LOAN_TAKEN_EXPORT_SUCCESS:
      return {
        ...state,
        loanTakenDataExport: action.payload,
      };
    case GET_LOAN_TAKEN_CHECK_EXPORT_SUCCESS:
      return {
        ...state,
        loanTakenDataExportCheck: action.payload,
      };

    case GET_CREDIT_CHECK_EXPORT_SUCCESS:
      return {
        ...state,
        creditDataExportCheck: action.payload,
      };
    case GET_FILTERED_RESEARCHERS_SUCCESS:
      return {
        ...state,
        filteredResearchers: action.payload,
      };

    case GET_All_ADMIN_USER_SUCCESS:
      return {
        ...state,
        adminUserList: action.payload,
      };

    case ON_ADD_ADMIN_USER_SUCCESS:
      return {
        ...state,
        adminUserList: [...state.adminUserList, action.payload],
      };

    case UPDATE_ADMIN_USER_SUCCESS:
      return {
        ...state,
        adminUserList: state.adminUserList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_ADMIN_USER_SUCCESS:
      return {
        ...state,
        adminUserList: state.adminUserList.filter(
          (user) => user.id !== action.payload
        ),
      };

    case GET_All_LENDER_USER_SUCCESS:
      return {
        ...state,
        lenderUserList: action.payload,
      };

    case ON_ADD_LENDER_USER_SUCCESS:
      return {
        ...state,
        lenderUserList: [...state.lenderUserList, action.payload],
      };

    case UPDATE_LENDER_USER_SUCCESS:
      return {
        ...state,
        lenderUserList: state.lenderUserList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_LENDER_USER_SUCCESS:
      return {
        ...state,
        lenderUserList: state.lenderUserList.filter(
          (user) => user.id !== action.payload
        ),
      };

    case GET_All_PORTAL_USER_SUCCESS:
      return {
        ...state,
        portalUserList: action.payload,
      };

    case ON_ADD_PORTAL_USER_SUCCESS:
      return {
        ...state,
        portalUserList: [...state.portalUserList, action.payload],
      };

    case UPDATE_PORTAL_USER_SUCCESS:
      return {
        ...state,
        portalUserList: state.portalUserList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_PORTAL_USER_SUCCESS:
      return {
        ...state,
        portalUserList: state.portalUserList.filter(
          (user) => user.id !== action.payload
        ),
      };

    case GET_All_RESEARCHER_USER_SUCCESS:
      return {
        ...state,
        researcherUserList: action.payload,
      };

    case ON_ADD_RESEARCHER_USER_SUCCESS:
      return {
        ...state,
        researcherUserList: [...state.researcherUserList, action.payload],
      };

    case UPDATE_RESEARCHER_USER_SUCCESS:
      return {
        ...state,
        researcherUserList: state.researcherUserList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_RESEARCHER_USER_SUCCESS:
      return {
        ...state,
        researcherUserList: state.researcherUserList.filter(
          (user) => user.id !== action.payload
        ),
      };

    case GET_All_INSTITUTION_USER_SUCCESS:
      return {
        ...state,
        institutionUserList: action.payload,
      };

    case ON_ADD_INSTITUTION_USER_SUCCESS:
      return {
        ...state,
        institutionUserList: [...state.institutionUserList, action.payload],
      };

    case UPDATE_INSTITUTION_USER_SUCCESS:
      return {
        ...state,
        institutionUserList: state.institutionUserList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_INSTITUTION_USER_SUCCESS:
      return {
        ...state,
        institutionUserList: state.institutionUserList.filter(
          (user) => user.id !== action.payload
        ),
      };

    case GET_All_CLIENT_USER_SUCCESS:
      return {
        ...state,
        clientUserList: action.payload,
      };

    case ON_ADD_CLIENT_USER_SUCCESS:
      return {
        ...state,
        clientUserList: [...state.clientUserList, action.payload],
      };

    case UPDATE_CLIENT_USER_SUCCESS:
      return {
        ...state,
        clientUserList: state.clientUserList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_CLIENT_USER_SUCCESS:
      return {
        ...state,
        clientUserList: state.clientUserList.filter(
          (user) => user.id !== action.payload
        ),
      };

    case GET_All_SURVEYOR_USER_SUCCESS:
      return {
        ...state,
        surveyorUserList: action.payload,
      };

    case ON_ADD_SURVEYOR_USER_SUCCESS:
      return {
        ...state,
        surveyorUserList: [...state.surveyorUserList, action.payload],
      };

    case UPDATE_SURVEYOR_USER_SUCCESS:
      return {
        ...state,
        surveyorUserList: state.surveyorUserList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_SURVEYOR_USER_SUCCESS:
      return {
        ...state,
        surveyorUserList: state.surveyorUserList.filter(
          (user) => user.id !== action.payload
        ),
      };

    case GET_All_EMPLOYER_USER_SUCCESS:
      return {
        ...state,
        employerUserList: action.payload,
      };

    case ON_ADD_EMPLOYER_USER_SUCCESS:
      return {
        ...state,
        employerUserList: [...state.employerUserList, action.payload],
      };

    case UPDATE_EMPLOYER_USER_SUCCESS:
      return {
        ...state,
        employerUserList: state.employerUserList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_EMPLOYER_USER_SUCCESS:
      return {
        ...state,
        employerUserList: state.employerUserList.filter(
          (user) => user.id !== action.payload
        ),
      };

    case GET_All_DEMOGRAPHIC_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        demographicUserList: action.payload,
      };

    case ON_ADD_DEMOGRAPHIC_SUCCESS:
      return {
        ...state,
        demographicUserList: [...state.demographicUserList, action.payload],
      };

    case UPDATE_DEMOGRAPHIC_SUCCESS:
      return {
        ...state,
        demographicUserList: state.demographicUserList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_DEMOGRAPHIC_SUCCESS:
      return {
        ...state,
        demographicUserList: state.demographicUserList.filter(
          (user) => user.id !== action.payload
        ),
      };

    case GET_All_EMPLOYEE_FORM_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        employeeFormList: action.payload,
      };

    case ON_ADD_EMPLOYEE_FORM_SUCCESS:
      return {
        ...state,
        employeeFormList: [...state.employeeFormList, action.payload],
      };

    case UPDATE_EMPLOYEE_FORM_SUCCESS:
      return {
        ...state,
        employeeFormList: state.employeeFormList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_EMPLOYEE_FORM_SUCCESS:
      return {
        ...state,
        employeeFormList: state.employeeFormList.filter(
          (user) => user.id !== action.payload
        ),
      };

    case GET_All_EMPLOYMENT_QUESTION_SUCCESS:
      return {
        ...state,
        employmentQUserList: action.payload,
      };

    case ON_ADD_EMPLOYMENT_QUESTION_SUCCESS:
      return {
        ...state,
        employmentQUserList: [...state.employmentQUserList, action.payload],
      };

    case UPDATE_EMPLOYMENT_QUESTION_SUCCESS:
      return {
        ...state,
        employmentQUserList: state.employmentQUserList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_EMPLOYMENT_QUESTION_SUCCESS:
      return {
        ...state,
        employmentQUserList: state.employmentQUserList.filter(
          (user) => user.id !== action.payload
        ),
      };

    case GET_All_LOAN_QUESTION_SUCCESS:
      return {
        ...state,
        loanQUserList: action.payload,
      };

    case ON_ADD_LOAN_QUESTION_SUCCESS:
      return {
        ...state,
        loanQUserList: [...state.loanQUserList, action.payload],
      };

    case UPDATE_LOAN_QUESTION_SUCCESS:
      return {
        ...state,
        loanQUserList: state.loanQUserList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_LOAN_QUESTION_SUCCESS:
      return {
        ...state,
        loanQUserList: state.loanQUserList.filter(
          (user) => user.id !== action.payload
        ),
      };

    case GET_All_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationUserList: action.payload,
      };

    case ON_ADD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationUserList: [...state.notificationUserList, action.payload],
      };

    case UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationUserList: state.notificationUserList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationUserList: state.notificationUserList.filter(
          (user) => user.id !== action.payload
        ),
      };

    case GET_All_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        assetTypeUserList: action.payload,
      };

    case ON_ADD_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        assetTypeUserList: [...state.assetTypeUserList, action.payload],
      };

    case UPDATE_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        assetTypeUserList: state.assetTypeUserList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        assetTypeUserList: state.assetTypeUserList.filter(
          (user) => user.id !== action.payload
        ),
      };

    case GET_All_AGRICULTURE_TYPE_SUCCESS:
      return {
        ...state,
        agriTypeUserList: action.payload,
      };


    case ON_ADD_AGRICULTURE_TYPE_SUCCESS:
      return {
        ...state,
        agriTypeUserList: [...state.agriTypeUserList, action.payload],
      };


    case UPDATE_AGRICULTURE_TYPE_SUCCESS:
      return {
        ...state,
        agriTypeUserList: state.agriTypeUserList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_AGRICULTURE_TYPE_SUCCESS:
      return {
        ...state,
        agriTypeUserList: state.agriTypeUserList.filter((user) => user.id !== action.payload),
      };

    case GET_All_BUSINESS_TYPE_SUCCESS:
      return {
        ...state,
        businessTypeUserList: action.payload,
      };


    case ON_ADD_BUSINESS_TYPE_SUCCESS:
      return {
        ...state,
        businessTypeUserList: [...state.businessTypeUserList, action.payload],
      };


    case UPDATE_BUSINESS_TYPE_SUCCESS:
      return {
        ...state,
        businessTypeUserList: state.businessTypeUserList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_BUSINESS_TYPE_SUCCESS:
      return {
        ...state,
        businessTypeUserList: state.businessTypeUserList.filter((user) => user.id !== action.payload),
      };

    case GET_All_EMPLOYMENT_TYPE_SUCCESS:
      return {
        ...state,
        employmentTypeUserList: action.payload,
      };


    case ON_ADD_EMPLOYMENT_TYPE_SUCCESS:
      return {
        ...state,
        employmentTypeUserList: [...state.employmentTypeUserList, action.payload],
      };


    case UPDATE_EMPLOYMENT_TYPE_SUCCESS:
      return {
        ...state,
        employmentTypeUserList: state.employmentTypeUserList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_EMPLOYMENT_TYPE_SUCCESS:
      return {
        ...state,
        employmentTypeUserList: state.employmentTypeUserList.filter((user) => user.id !== action.payload),
      };

    case GET_All_OWNERSHIP_TYPE_SUCCESS:
      return {
        ...state,
        ownershipTypeUserList: action.payload,
      };


    case ON_ADD_OWNERSHIP_TYPE_SUCCESS:
      return {
        ...state,
        ownershipTypeUserList: [...state.ownershipTypeUserList, action.payload],
      };


    case UPDATE_OWNERSHIP_TYPE_SUCCESS:
      return {
        ...state,
        ownershipTypeUserList: state.ownershipTypeUserList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_OWNERSHIP_TYPE_SUCCESS:
      return {
        ...state,
        ownershipTypeUserList: state.ownershipTypeUserList.filter((user) => user.id !== action.payload),
      };

    case GET_All_NON_WORK_INCOME_TYPE_SUCCESS:
      return {
        ...state,
        nonWorkIncomeUserList: action.payload,
      };


    case ON_ADD_NON_WORK_INCOME_TYPE_SUCCESS:
      return {
        ...state,
        nonWorkIncomeUserList: [...state.nonWorkIncomeUserList, action.payload],
      };


    case UPDATE_NON_WORK_INCOME_TYPE_SUCCESS:
      return {
        ...state,
        nonWorkIncomeUserList: state.nonWorkIncomeUserList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_NON_WORK_INCOME_TYPE_SUCCESS:
      return {
        ...state,
        nonWorkIncomeUserList: state.nonWorkIncomeUserList.filter((user) => user.id !== action.payload),
      };

    case GET_All_EXPENSE_TYPE_SUCCESS:
      return {
        ...state,
        expenseTypeUserList: action.payload,
      };


    case ON_ADD_EXPENSE_TYPE_SUCCESS:
      return {
        ...state,
        expenseTypeUserList: [...state.expenseTypeUserList, action.payload],
      };


    case UPDATE_EXPENSE_TYPE_SUCCESS:
      return {
        ...state,
        expenseTypeUserList: state.expenseTypeUserList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_EXPENSE_TYPE_SUCCESS:
      return {
        ...state,
        expenseTypeUserList: state.expenseTypeUserList.filter((user) => user.id !== action.payload),
      };

    case GET_All_COST_TYPE_SUCCESS:
      return {
        ...state,
        costTypeUserList: action.payload,
      };


    case ON_ADD_COST_TYPE_SUCCESS:
      return {
        ...state,
        costTypeUserList: [...state.costTypeUserList, action.payload],
      };


    case UPDATE_COST_TYPE_SUCCESS:
      return {
        ...state,
        costTypeUserList: state.costTypeUserList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_COST_TYPE_SUCCESS:
      return {
        ...state,
        costTypeUserList: state.costTypeUserList.filter((user) => user.id !== action.payload),
      };

    case GET_All_SAVING_TOOLE_SUCCESS:
      return {
        ...state,
        savingToolUserList: action.payload,
      };


    case ON_ADD_SAVING_TOOL_SUCCESS:
      return {
        ...state,
        savingToolUserList: [...state.savingToolUserList, action.payload],
      };


    case UPDATE_SAVING_TOOL_SUCCESS:
      return {
        ...state,
        savingToolUserList: state.savingToolUserList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_SAVING_TOOL_SUCCESS:
      return {
        ...state,
        savingToolUserList: state.savingToolUserList.filter((user) => user.id !== action.payload),
      };

    case GET_All_LOAN_TOOLE_SUCCESS:
      return {
        ...state,
        loanToolUserList: action.payload,
      };


    case ON_ADD_LOAN_TOOL_SUCCESS:
      return {
        ...state,
        loanToolUserList: [...state.loanToolUserList, action.payload],
      };


    case UPDATE_LOAN_TOOL_SUCCESS:
      return {
        ...state,
        loanToolUserList: state.loanToolUserList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_LOAN_TOOL_SUCCESS:
      return {
        ...state,
        loanToolUserList: state.loanToolUserList.filter((user) => user.id !== action.payload),
      };

    case GET_All_LANGUAGE_SUCCESS:
      return {
        ...state,
        languageUserList: action.payload,
      };


    case ON_ADD_LANGUAGE_SUCCESS:
      return {
        ...state,
        languageUserList: [...state.languageUserList, action.payload],
      };


    case UPDATE_LANGUAGE_SUCCESS:
      return {
        ...state,
        languageUserList: state.languageUserList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_LANGUAGE_SUCCESS:
      return {
        ...state,
        languageUserList: state.languageUserList.filter((user) => user.id !== action.payload),
      };


    case GET_All_COMBINED_REPORT_DATA_SUCCESS: {
      return {
        ...state,
        combinedReportData: action.payload,
      };
    }

    case GET_All_USER_SPECIFIC_REPORT_DATA_SUCCESS : {
      return {
        ...state,
        userSpecificReportData: action.payload,
      };
    }

    case GET_All_SURVEY_DATA_SUCCESS:
      return {
        ...state,
        surveyDataList: action.payload,
      };

    case ON_ADD_SURVEY_DATA_SUCCESS:
      return {
        ...state,
        surveyDataList: [...state.surveyDataList, action.payload],
      };


    case UPDATE_SURVEY_DATA_SUCCESS:
      return {
        ...state,
        surveyDataList: state.surveyDataList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_SURVEY_DATA_SUCCESS:
      return {
        ...state,
        surveyDataList: state.surveyDataList.filter((user) => user.id !== action.payload),
      };

    case GET_All_EMPLOYEE_SURVEY_DATA_SUCCESS:
      return {
        ...state,
        employeeSurveyDataList: action.payload,
      };

    case ON_ADD_EMPLOYEE_SURVEY_DATA_SUCCESS:
      return {
        ...state,
        employeeSurveyDataList: [...state.employeeSurveyDataList, action.payload],
      };


    case UPDATE_EMPLOYEE_SURVEY_DATA_SUCCESS:
      return {
        ...state,
        employeeSurveyDataList: state.employeeSurveyDataList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_EMPLOYEE_SURVEY_DATA_SUCCESS:
      return {
        ...state,
        employeeSurveyDataList: state.employeeSurveyDataList.filter((user) => user.id !== action.payload),
      };

    case GET_All_PROJECT_SUCCESS:
      return {
        ...state,
        projectList: action.payload,
      };

    case ON_ADD_PROJECT_SUCCESS:
      return {
        ...state,
        projectList: [...state.projectList, action.payload],
      };


    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        projectList: state.projectList.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        projectList: state.projectList.filter((user) => user.id !== action.payload),
      };

    case GET_GENERATED_REPORTS_SUCCESS:
      return {
        ...state,
        generatedReports: action.payload
      };

    case GET_EMPLOYEE_SURVEY_GENERATED_REPORTS_SUCCESS:
      return {
        ...state,
        employeeSurveyGeneratedReports: action.payload
      };

    case GET_GENERATED_INCOME_REPORTS_SUCCESS:
      return {
        ...state,
        incomeGeneratedReports: action.payload
      };

    case GET_GENERATED_EXPENSE_REPORTS_SUCCESS:
      return {
        ...state,
        expenseGeneratedReports: action.payload
      };

    case GET_GENERATED_CREDIT_REPORTS_SUCCESS:
      return {
        ...state,
        creditGeneratedReports: action.payload
      };

    case GET_GENERATED_LOAN_GIVEN_REPORTS_SUCCESS:
      return {
        ...state,
        loanGivenGeneratedReports: action.payload
      };

    case GET_GENERATED_SAVING_REPORTS_SUCCESS:
      return {
        ...state,
        savingGeneratedReports: action.payload
      };

    case GET_GENERATED_ASSET_REPORTS_SUCCESS:
      return {
        ...state,
        assetGeneratedReports: action.payload
      };

    case GET_GENERATED_EMPLOYEE_REPORTS_SUCCESS:
      return {
        ...state,
        employeeGeneratedReports: action.payload
      };

    case GET_DEMOGRAPHICS_ORDER_SUCCESS:
      return {
        ...state,
        demographicOrder: action.payload,
      };

    case ON_ADD_DEMOGRAPHIC_ORDER_SUCCESS:
      return {
        ...state,
        demographicOrder: action.payload,
      };


    case UPDATE_DEMOGRAPHIC_ORDER_SUCCESS:
      return {
        ...state,
        demographicOrder: action.payload,
      };

    case GET_DOWNLOAD_LINK_SUCCESS:
      return {
        ...state,
        downloadLink: action.payload,
      };

    default:
      return state;
  }
};
