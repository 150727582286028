import { useState, useEffect } from "react";
import axios from "../../util/Api";

function useLanguages() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    setData([]);
    axios
      .get(`/language/`)
      .then(({ data }) => setData(data))
      .finally(() => setLoading(false));
  }, []);

  return [loading, data];
}

export default useLanguages;
